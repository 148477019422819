import thdcustomer from 'thd-customer';

let order = {};

const removeB2XFooterLinks = () => {
  let b2xElem = document.querySelector('#B2XCheckOrder');
  if (thdcustomer.isB2XCustomer) {
    document.querySelector('#B2CCheckOrder').remove();
    b2xElem.classList.remove('u--hide');
  } else {
    b2xElem.remove();
  }
};

order.init = function () {
  removeB2XFooterLinks();
};

export default order;