import * as $ from 'jquery';
import * as hfConfig from 'hfConfig';
import * as thdCustomer from 'thd-customer';
import * as analytics from 'header.analytics';

let instance;

class BuyItAgainFlyOut {
  constructor() {
    this.hostName = hfConfig.secureHostName;
    this.isAnimated = false;
    this.title = '';
    this.failureAPICounter = 0;
    this.shouldRender = false;
    this.tooltipHeight = 0;
    this.productCount = 0;

    this.init = this.init.bind(this);
    this.render = this.render.bind(this);
  }

  buildAnalyticsPayload(data, type, idx) {
    const { productId, messageVersion, interval } = data;
    const pageType = "homepage";

    if (type === "pip") {
      return {
        pageType,
        strategy: messageVersion,
        displayPosition: idx,
        interval
      }
    }

    if (type === "addToCart") {
      return {
        pageType,
        interval,
        sku: productId,
        displayPosition: idx
      }
    }
  }

  onClickProductInfo(e, product, idx) {
    e.preventDefault();

    const { logBuyItAgainClickToPIP } = analytics;
    const payload = this.buildAnalyticsPayload(product, "pip", idx);

    logBuyItAgainClickToPIP(payload);
    window.location = `//${this.hostName}${product.canonicalURL}`;
  }

  onClickAddToCart(e, product, idx) {
    e.preventDefault();

    const { productId } = product;
    const { logBuyItAgainAddToCart } = analytics;
    const payload = this.buildAnalyticsPayload(product, "addToCart", idx);

    logBuyItAgainAddToCart(payload);
    this.postCartAPI(productId)
      .done((res) => {
        if (res.hasOwnProperty('CartModel')) {
          window.location = `https://${this.hostName}/mycart/home`;
        }
      })
  }

  postCartAPI(id) {
    const url = `https://${this.hostName}/mcc-cart/v2/Cart`;
    const data = {
      CartRequest: {
        itemDetails: [{
          itemId: id,
          quantity: 1
        }]
      }
    };

    return $.ajax({
      url,
      type: 'POST',
      headers: {
        Accept: 'application/json;charset=utf-8',
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      },
      data: JSON.stringify(data)
    })
  }

  getAPI(useJsonP) {
    const svocId = thdCustomer.default ? thdCustomer.default.svocID : thdCustomer.svocID;
    const key = this.hostName === 'www.homedepot.com' ? 'lKVIR4lewGZorcvSQtuFdP09mB5dsHGa' : 'AOcCxiFFbCGAWzoR9ZoHQFdG62IsmWmG';
    const url = `https://${this.hostName}/thdrecommends/v1/${svocId}/reorder`;

    if (useJsonP) {
      return $.ajax({
        url,
        type: 'GET',
        dataType: 'jsonp',
        data: {
          appid: 'desktop',
          storeid: '121',
          version: 'model1',
          maxproducts: '10',
          key,
          showprice: true
        },
        timeout: 750
      });
    } else {
      return $.ajax({
        url,
        type: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        data: {
          appid: 'desktop',
          storeid: '121',
          version: 'model1',
          maxproducts: '10',
          key,
          showprice: true
        },
      });
    }
  };

  createView(data) {
    const title = $('.recommendations__title');
    const contents = $('.recommendations__contents');

    // step 2. remove all child in grid
    title.empty();
    contents.empty();
    // step 3. add title
    title.html(data.title);
    // step 4. add products in the content
    let products;
    if (data.products && data.products.length) {
      products = data.products.filter(prod => !prod.hasOwnProperty('mapAboveOriginalPrice')).map((product, idx) => {
        if (idx < 5) { // limit to 5 items
          const imageURL = product.imageURL.replace('<SIZE>', '100');
          const [dollar, cents] = product.price.toString().split(".");

          return (
            $('<div></div>').addClass(`recommendations__product-desc`).append(
              $('<a></a>')
                .addClass('recommendations__product-link')
                .click((e) => this.onClickProductInfo(e, product, idx)).append(
                $('<img/>').attr('src', imageURL).addClass('recommendations__product-img')
              ),
              $('<div></div>').addClass('recommendations__product-detail').append(
                $('<h4></h4>')
                  .addClass('recommendations__product-link')
                  .click((e) => this.onClickProductInfo(e, product, idx))
                  .addClass('recommendations__product-name').append(
                    $('<span></span>').html(product.brand),
                    `&nbsp;${product.productName}`
                ),
                $('<div></div>').addClass('recommendations__product-links')
                  .append(
                    $('<div></div>')
                      .addClass('recommendations__product-link')
                      .click((e) => this.onClickProductInfo(e, product, idx))
                      .addClass('price')
                      .append(
                        $('<div></div>').addClass('price__numbers').append(
                          $('<span></span>').addClass('price__format').html('$'),
                          dollar,
                          $('<span></span>').addClass('price__format').html(cents)
                        )
                      ),
                    $('<a></a>')
                      .addClass("bttn-outline bttn-outline--primary add-to-cart__bttn")
                      .click((e) => this.onClickAddToCart(e, product, idx))
                      .append(
                        $('<span></span>').addClass("bttn__content").html("Add to Cart")
                      )
                  ),
                )
            )
          )
        }
        return null;
      });

      if (!products.length) {
        this.shouldRender = false;
        return;
      }

      this.productCount = products.length;
      this.shouldRender = true;
      this.isAnimated = false;
      contents.append(products);
    }
  };

  setGridHeight(tooltip) {
    this.tooltipHeight = tooltip.height();
    $('.recommendations__grid').height(this.tooltipHeight - 20);
    tooltip.parent().addClass("recommendations__wrapper");
  }

  render() {
    if (sessionStorage && sessionStorage.getItem('recommendations') && this.shouldRender) {
      $('.recommendations__contents').height(this.tooltipHeight - $('.recommendations__title').height() - 30);

      if (this.productCount === 5) {
        $('.recommendations__contents').css('justify-content', 'space-between');
      } else {
        $('.recommendations__product-desc').css('padding', '10px 0');
      }

      const recommendationGrid = $('.recommendations__grid');

      this.isAnimated ?
        recommendationGrid.removeClass("recommendations__slide").addClass("recommendations__show").addClass("ToolTip__wrapper--bottom") :
        recommendationGrid.addClass('recommendations__slide');
    }
  };

  init(useJsonP) {
    /**
     * Getting recommendation items for buy it again feature
     * Listening on mouseenter event to target web view, excluding mobile users.
     */
    if (sessionStorage) {
      // removing recommendations from web Storage on initialization of the hfapp to get fresh data
      sessionStorage.removeItem('recommendations');
      $(document).on('click', '#headerMyAccount', () => {
        const timestamp = Date.now();
        if (this.failureAPICounter < 5) {
          if (!sessionStorage.getItem('recommendations')) {
            this.getAPI(useJsonP)
              .done((res) => {
                this.title = res.title;

                $('.MyAccount__dropdown').children('div.grid').addClass('MyAccount__dropdown--content');
                if (!res.hasOwnProperty('errorMessage')) {
                  sessionStorage.setItem('recommendations', JSON.stringify({ ...res, timestamp }));
                  this.createView({ ...res, timestamp });
                  this.failureAPICounter = 0;
                } else {
                  if (res.errorMessage === "No recommendations ") {
                    $(document).off('click', '#headerMyAccount');
                    return;
                  }

                  this.failureAPICounter++;
                }
              })
              .done(() => {
                this.render();
                this.isAnimated = true;
              })
              .fail(e => {
                this.failureAPICounter++;
                $('.MyAccount__dropdown').children('div.grid').removeClass('MyAccount__dropdown--content');
                console.error(`Error from getting Reorder Recommendations initially, ${JSON.stringify(e)}`)
              })
          } else {
            let recommendations = JSON.parse(sessionStorage.getItem('recommendations'));
            if (Date.now() - recommendations.timestamp >= 3600000) { // after 2 hours get fresh data
              this.getAPI(useJsonP)
                .done((res) => {
                  this.title = res.title;
                  $('.MyAccount__dropdown').children('div.grid').addClass('MyAccount__dropdown--content');
                  if (!res.hasOwnProperty('errorMessage')) {
                    sessionStorage.setItem('recommendations', JSON.stringify({ ...res, timestamp }));
                    this.createView({ ...res, timestamp });
                    this.failureAPICounter = 0;
                  } else {
                    if (res.errorMessage === "No recommendations ") {
                      $(document).off('click', '#headerMyAccount');
                      return;
                    }

                    this.failureAPICounter++;
                  }
                })
                .done(() => {
                  this.render();
                  this.isAnimated = true;
                })
                .fail(e => {
                  this.failureAPICounter++;
                  $('.MyAccount__dropdown').children('div.grid').removeClass('MyAccount__dropdown--content');
                  console.error(`Error from getting Reorder Recommendations later, ${JSON.stringify(e)}`)
                })
            }
          }
        }
        if (this.isAnimated) this.render();
      });
    }

    return null;
  };
}

if (!instance) {
  instance = new BuyItAgainFlyOut();
}

export default instance;
