/* globals define */
/*
  Purpose:
    - to handle closing of header tooltips and triggering events when tooltip is open

  Dependencies
    - jquery

*/


(function closure(root, factory) {
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('header.tooltips', ['jquery', 'hfConfig'],
      function load(jQuery, hfConfig) {
        return factory(root, jQuery, hfConfig);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.tooltips = factory(root, jQuery, hfConfig);
    /*eslint-enable*/
  }
})(this, function factory(root, $, hfConfig) {
  //debugger;
  var tooltips = {};
  var privateMethods = {};
  var isStickyHeaderEnable = false;
  var myaccountOffSet;
  var mystoreOffSet;

  privateMethods.closeHeaderTooltips = function _closeHeaderTooltips(){
    $('#headerCart').tooltip('close');
    $('#headerMyAccount').tooltip('close');
    $(document).trigger('closeStoreFinder', false);
  }

  privateMethods.openingHeaderToolTip = function _openingHeaderToolTip(event, data){
    var el = $('.Header3');
    if ((el.hasClass('Header3-sticky') || el.hasClass('Header3-sticky-desktop')) && !isStickyHeaderEnable) {
      privateMethods.headerSticky_tooltipInit();
    }
    $(document).trigger('showSimpleFlyout', false);
    $(document).trigger('closeAllMenus');

    if(data !== 'storefinder'){
      $(document).trigger('closeStoreFinder', false);
    }
    if(isStickyHeaderEnable){
      if(data === 'storefinder' || data === 'myaccount'){
        privateMethods.modifyTooltipProp(data);
      }
      if(data === 'deliveryZip'){
        privateMethods.modifyTooltipPropForDeliveryZip(data);
      }
    }
  }

  privateMethods.modifyTooltipPropForDeliveryZip = function _modifyTooltipPropForDeliveryZip(data){
    setTimeout(function(){
      var toolTipElementZip = $('#deliveryZipDropdown');
      var toolTipContainerZip = toolTipElementZip.parents('.ToolTip');
      var setTopZip = (deliveryZipOffSet + $('#zipContainer').height());
      toolTipContainerZip.css('top', setTopZip);
      toolTipContainerZip.addClass('header-tooltip-zindex');
      toolTipContainerZip.css('position', 'fixed');
      toolTipContainerZip.children('.ToolTip__wrapper--bottom').css('height', '214px');
      toolTipContainerZip.css('bottom', 'auto');
      if(toolTipContainerZip.children('.ToolTip__arrow').hasClass('ToolTip__arrow--top')){
        toolTipContainerZip.children('.ToolTip__content').removeClass('ToolTip__content--top').addClass('ToolTip__content--bottom');
        toolTipContainerZip.children('.ToolTip__wrapper').removeClass('ToolTip__wrapper--top').addClass('ToolTip__wrapper--bottom');
        toolTipContainerZip.children('.ToolTip__arrow').removeClass('ToolTip__arrow--top').addClass('ToolTip__arrow--bottom');
        toolTipContainerZip.removeClass('ToolTip--top').addClass('ToolTip--bottom');
      }
    }, 50);
  }


  privateMethods.modifyTooltipProp = function _modifyTooltipProp(data){
    setTimeout(function(){
      var toolTipElement = (data === 'storefinder') ? $('#myStoreDropdown') : $('#headerMyAccountDropdown');
      var toolTipContainer = toolTipElement.parents('.ToolTip');
      var setTop = (data === 'storefinder') ? (mystoreOffSet + $('.MyStore').height()) : (myaccountOffSet + $('.MyAccount').height());
      // setTop = $('#TasklinkList') ? setTop + $('#TasklinkList').height() : setTop;
      toolTipContainer.css('top', setTop);
      toolTipContainer.addClass('header-tooltip-zindex');
      toolTipContainer.css('position', 'fixed');
      toolTipContainer.css('bottom', 'auto');
      if(toolTipContainer.children('.ToolTip__arrow').hasClass('ToolTip__arrow--top')){
        toolTipContainer.children('.ToolTip__content').removeClass('ToolTip__content--top').addClass('ToolTip__content--bottom');
        toolTipContainer.children('.ToolTip__wrapper').removeClass('ToolTip__wrapper--top').addClass('ToolTip__wrapper--bottom');
        toolTipContainer.children('.ToolTip__arrow').removeClass('ToolTip__arrow--top').addClass('ToolTip__arrow--bottom');
        toolTipContainer.removeClass('ToolTip--top').addClass('ToolTip--bottom');
      }
    }, 50);
  }

  privateMethods.breakpointChanged = function _breakpointChanged(event, eventInfo) {
    var newInList = ['lg', 'md', 'sm'].indexOf(eventInfo.new);
    var oldInList = ['default', 'xs'].indexOf(eventInfo.old);

    // If we're in a smaller break point
    if ((newInList === -1) && (oldInList === -1)) {
      privateMethods.closeHeaderTooltips();
    }
  };

  privateMethods.initialize = function initialize() {
    $(document)
      .on('closeHeaderTooltips', privateMethods.closeHeaderTooltips)
      .on('openingHeaderToolTip', privateMethods.openingHeaderToolTip)
      .on('searchState', privateMethods.closeHeaderTooltips)
      .on('breakpointChanged', privateMethods.breakpointChanged)
      .on('hideHeaderDropdowns', privateMethods.closeHeaderTooltips);
  };

  privateMethods.headerSticky_tooltipInit = function _headerSticky_tooltipInit(){
    isStickyHeaderEnable = true;
    myaccountOffSet = $('.MyAccount').offset().top - $(document).scrollTop();
    mystoreOffSet = $('.MyStore').offset().top - $(document).scrollTop();
    deliveryZipOffSet = $('#zipContainer').offset().top - $(document).scrollTop();
    $('#messageBar').css('border-top', 0);
    $('#myStoreModule, .drop-down__header').css('z-index', 99);
    $( window ).on('scroll', function() {
      $('.ToolTip').css('z-index', 99);
      $('#myStoreDropdown, #headerMyAccountDropdown, #deliveryZipDropdown').parents('.ToolTip').css('z-index', 101);
    });
  }
  
  /*
   * Public Methods
   */
  tooltips.init = function init() {
    
    if(THD_GLOBAL.enableBassDesktopStickyHeader || THD_GLOBAL.enableHomeDesktopStickyHeader){
      var isHomeDecor = $('.homeDecorFurnitureSubnav')[0] ? true : false;
      if (!isHomeDecor) {
        var el = $('.Header3');
        var headerClass = hfConfig.currentChannel === 'web' ? 'Header3-sticky-desktop' : 'Header3-sticky';
        el.addClass(headerClass);
        // privateMethods.headerSticky_tooltipInit();
      }
    }
    privateMethods.initialize();
    return this;
  };


  return tooltips;

});
