/* globals define */
/*
  Purpose:
    - UI controller for the Specials Flyout navigation list.

  Dependencies
    - Mustache
    - jQuery
    - header.data
*/
/* eslint-disable */
(function closure(root, factory) {
/*eslint-enable*/
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('header.specialsflyout', ['jquery', 'mustache', 'header.data', 'text!specialsWebFlyoutTpl', 'header.analytics'],
      function load(jQuery, Mustache, headerData, specialsFlyoutWebTemplate, headerAnalytics) {
        return factory(root, jQuery, Mustache, headerData, specialsFlyoutWebTemplate, headerAnalytics);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.flyoutSpecials = factory(root, jQuery, Mustache, THD.HFApp.data, specialsFlyoutWebTemplate, headerAnalytics);
    /*eslint-enable*/
  }
})(this, function factory(root, $, Mustache, headerData, specialsFlyoutWebTemplate, headerAnalytics) {
  var specialsFlyout = {};
  var specialsFlyoutData;
  var privateMethods = {};

  privateMethods.specialsFlyoutJsonPath = '/hdus/en_US/DTCCOMNEW/fetch/specials-flyout.json';
  privateMethods.specialsFlyoutJsonDynamicPath = '/SpecialBuy/specials-flyout';

  privateMethods.renderSubnav = function _renderSubnav() {
    if (specialsFlyoutData) {
      $('.specialOffersFlyout').html(Mustache.render(specialsFlyoutWebTemplate, specialsFlyoutData));
    }
  };

  privateMethods.updateSavingsByDepartmentData = function _updateSavingsByDepartmentData() {

    if (specialsFlyoutData.specialsflyout.savingsByDepartment) {
      var link = specialsFlyoutData.specialsflyout.savingsByDepartment;
      if (link.title === 'Savings By Department') {
        var colCount = 1;
        var sublink = {};
        for (var sublinkIndex = 0; sublinkIndex < link.sublinks.length; sublinkIndex++) {
          if (!sublink['col' + colCount]) {
            sublink['col' + colCount] = [];
          }
          sublink['col' + colCount].push(link.sublinks[sublinkIndex]);
          if ((sublinkIndex + 1) % 3 === 0) {
            colCount++;
          }
        }
        link.sublinks = sublink;
      }
    }
  };

  // Renders desktop specials and offers flyout
  privateMethods.getFlyoutData = function _getFlyoutData() {
    if (!specialsFlyoutData) {
      privateMethods.getFlyoutDataAll(function (flyoutData) {
        specialsFlyoutData = flyoutData;
        privateMethods.updateSavingsByDepartmentData();
        privateMethods.renderSubnav();
      });
    } else {
      privateMethods.renderSubnav();
    }
  };

  privateMethods.getUpdatedSpecialsData = function _getUpdatedSpecialsData() {
    return specialsFlyoutData;
  };

  privateMethods.setUpdatedSpecialsData = function _setUpdatedSpecialsData(newSpecialsFlyoutData) {
    specialsFlyoutData = newSpecialsFlyoutData;
  };

  privateMethods.validateAndUpdateFlyoutData = function _validateAndUpdateFlyoutData(flyoutData) {

    // More Ways To Save
    if (flyoutData.specialsflyout.moreWaysToSave) {
      var moreWaysToSaveSublinks = flyoutData.specialsflyout.moreWaysToSave.sublinks;
      
      if (moreWaysToSaveSublinks) {
        moreWaysToSaveSublinks.forEach((link) => {
          if (link.link === 'https://www.homedepot.com/c/Savings_Center') {
            link.isSpecOff = true;
          }
        });
      }
    }

    // Featured Savings
    if (flyoutData.specialsflyout.featuredSavings) {
      if (
        !flyoutData.specialsflyout.featuredSavings.sublinks ||
        !flyoutData.specialsflyout.featuredSavings.sublinks.length ||
        !flyoutData.specialsflyout.featuredSavings.image ||
        flyoutData.specialsflyout.featuredSavings.isDisabled
      ) {
        flyoutData.specialsflyout.featuredSavings.isDisabled = true;
        delete flyoutData.specialsflyout.featuredSavings
      }
          
      if (flyoutData.specialsflyout.featuredSavings) {
        flyoutData.specialsflyout.featuredSavings.link = flyoutData.specialsflyout.featuredSavings.sublinks[0].link;
      }
    }

    // Savings Event Banner
    var savingsEventBanner = flyoutData.specialsflyout.savingsEventBanner;
    if (savingsEventBanner && flyoutData.specialsflyout.savingsByDepartment) {
      if (
        !savingsEventBanner.title ||
        !savingsEventBanner.image ||
        !savingsEventBanner.link  ||
        savingsEventBanner.isDisabled
      ) {
        delete flyoutData.specialsflyout.savingsEventBanner;
      }
      else {
        savingsEventBanner.analyticsTarget = 'savingsEvent';
        savingsEventBanner.isSavingsEvent = true;
      }
    }
  };

  privateMethods.setFlyoutLinks = function _setFlyoutLinks(flyoutData) {
    var links = [];
    flyoutData.specialsflyout.links = links;

    var sbotd = flyoutData.specialsflyout.specialBuyOfTheDay;
    var sbotw = flyoutData.specialsflyout.proSpecialBuyOfTheWeek;
    var featuredSavings = flyoutData.specialsflyout.featuredSavings;
    var moreWaysToSave = flyoutData.specialsflyout.moreWaysToSave;
    var savingsByDepartment = flyoutData.specialsflyout.savingsByDepartment;
    var savingsEventBanner = flyoutData.specialsflyout.savingsEventBanner;

    if (sbotd) {
      links.push(sbotd);
    }
    if (featuredSavings) {
      links.push(featuredSavings);
    } else if (sbotw) {
      links.push(sbotw);
    }
    if (savingsEventBanner) {
      links.push(savingsEventBanner);
    }
    if (moreWaysToSave) {
      links.push(moreWaysToSave);
    }
    if (savingsByDepartment) {
      links.push(savingsByDepartment);
    }
  };

  // retrieve and merge static and dynamic flyout data
  privateMethods.getFlyoutDataAll = function _getFlyoutDataAll(callback) {
    /* eslint-disable */
    var flyoutDataRetrieved = $.Deferred();
    /* eslint-enable */

    flyoutDataRetrieved.then(function (flyoutData) {
      callback(flyoutData);
    });

    var responseDataPromise = headerData.getEtchJSON(privateMethods.specialsFlyoutJsonPath);
    var responseDataDynnamicPromise = privateMethods.getDynamicEtchJSON(privateMethods.specialsFlyoutJsonDynamicPath);
    var flyoutData = {};
    responseDataPromise.then(function _responseDataPromise(jsonResponse) {
      flyoutData = jsonResponse;
      flyoutData = privateMethods.transformFlyoutData(flyoutData);
      responseDataDynnamicPromise.then(function _responseDataDynamicPromise(jsonDynamicResponse) {
        privateMethods.updateFlyoutData(flyoutData, jsonDynamicResponse);
        privateMethods.validateAndUpdateFlyoutData(flyoutData);
        privateMethods.setFlyoutLinks(flyoutData);

        flyoutDataRetrieved.resolve(flyoutData);
      }).fail(function _responseDataDynamicError() {
        privateMethods.validateAndUpdateFlyoutData(flyoutData);
        privateMethods.setFlyoutLinks(flyoutData);

        flyoutDataRetrieved.resolve(flyoutData);
      });
    });
  };

  privateMethods.transformFlyoutData = function _transformFlyoutData(flyoutData) {
    var transformedData = {
      specialsflyout: {},
    };

    var keyMap = {
      'Special Buy Of The Day': 'specialBuyOfTheDay',
      'Featured Savings': 'featuredSavings',
      'More Ways To Save': 'moreWaysToSave',
      'Savings By Department': 'savingsByDepartment',
      'Pro Special Buy Of The Week': 'proSpecialBuyOfTheWeek',
    };

    if (flyoutData && flyoutData.specialsflyout && flyoutData.specialsflyout.links) {
      flyoutData.specialsflyout.links.forEach(function(link) {
        if (link.contentType === 'savingsEventBanner') {
          transformedData.specialsflyout.savingsEventBanner = link;
        } else if (link.title) {
          if (keyMap[link.title.trim()]) {
            transformedData.specialsflyout[keyMap[link.title.trim()]] = link;
          } else {
            transformedData.specialsflyout[link.title.replace(/\s+/g, '')] = link;
          }
        }
        
      });
    }

    return transformedData;
  };

  privateMethods.updateFlyoutData = function _updateFlyoutData(flyoutData, dynamicFlyoutData) {
    var specialsflyout = flyoutData.specialsflyout;
    var keyMap = {
      'specialBuy': 'specialBuyOfTheDay',
      'featuredSavings': 'featuredSavings',
      'proSpecialBuyOfTheWeek': 'proSpecialBuyOfTheWeek',
      'specialEvents': 'specialEvents',
      'savingsEventBanner': 'savingsEventBanner',
    };

    Object.keys(dynamicFlyoutData).forEach(function(key) {
      var flyoutKey = keyMap[key];
      if (specialsflyout[flyoutKey]) {
        specialsflyout[flyoutKey] = $.extend(specialsflyout[flyoutKey], dynamicFlyoutData[key]);

        if (specialsflyout[flyoutKey].links) {
          specialsflyout[flyoutKey].sublinks = specialsflyout[flyoutKey].links;
          delete specialsflyout[flyoutKey].links;
        }
      } else {
        specialsflyout[key] = dynamicFlyoutData[key];
      }
    });
  };

  privateMethods.getDynamicEtchJSON = function _getDynamicEtchJSON(jsonPath) {
    /* eslint-disable */
    var loadDataDefer = $.Deferred();
    /* eslint-enable */
    var hostName = headerData.getHostName();
    var ajaxUrl = '//' + hostName + jsonPath;
    $.ajax({
      dataType: 'json',
      url: ajaxUrl,
      cache: true,
      timeout: 3000,
      success: function (data) {
        loadDataDefer.resolve(data);
      },
      error: function () {
        loadDataDefer.reject('Failed to load flyout data.');
      }
    });
    return loadDataDefer.promise();
  };

  privateMethods.logEventClick = function _logEventClick(event) {
    if (event.currentTarget.href) {
      var selectedText = $(this).text().replace('&', 'and').toLowerCase().trim();
      var target = $(this).closest('div[data-id]').data('id');
      if (target === 'proSpecialBuyOfTheWeek') {
        selectedText = 'pro special buy of the week';
      } else if (target === 'featuredSavings') {
        selectedText = 'featured savings';
      } else if (target === 'specialBuyOfTheDay') {
        selectedText = 'special buy of the day';
      } else if (target === 'savingsEvent') {
        selectedText = 'special savings event';
      } else if ($(this).attr('class') === 'savingsEventsImg') {
        selectedText = 'special event';
      }
      var pathArray =  ['header', 'specials and offers', selectedText].join('>');
      return headerAnalytics.logEvent('header click', pathArray);
    }
  };

  privateMethods.triggerHoverIntent = function _triggerHoverIntent() {
    $(this).trigger({
      type: 'hoverintent',
      target: this
    });
  };

  privateMethods.initialize = function _initialize() {
    $(document)
      .on('click touchend hoverintent', '.Header3 .ShoppingLinks__link[data-id="specialOffers"]', privateMethods.getFlyoutData)
      .on('click', '.specialOffersFlyout__level2link', privateMethods.logEventClick)
      .on('click', '.specialOffersFlyout__titlelink', privateMethods.logEventClick)
      .on('click', '.sbotd_imgLink, .sbotw_imgLink, .savingsEvent', privateMethods.logEventClick)
      .on('click', '.featuredSavings_imgLink', privateMethods.logEventClick)
      .on('click', '.savingsEventsImg', privateMethods.logEventClick)
      .hoverIntent({
        selector: '.Header3 .ShoppingLinks__link[data-id="specialOffers"]',
        over: privateMethods.triggerHoverIntent,
        out: $.noop
      });
  };

  /*
   * Public Methods
   */
  specialsFlyout.init = function init() {
    privateMethods.initialize();
    headerData.init();

    return this;
  };

  specialsFlyout.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  specialsFlyout.getFlyoutData = function getFlyoutData(callback) {
    return privateMethods.getFlyoutDataAll(callback);
  };

  return specialsFlyout;
});
