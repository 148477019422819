(function closure(root, factory) {
  /* istanbul ignore else  */
  // object assign polyfill for IE
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target === null || target === undefined) {
          throw new TypeError('Cannot convert undefined or null to object');
        }

        const to = Object(target);

        for (let index = 1; index < arguments.length; index++) {
          const nextSource = arguments[index];

          if (nextSource !== null && nextSource !== undefined) {
            for (const nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }
  if (typeof define === 'function' && define.amd) {
    define(
      'header.homedecorfurnitureflyout', ['jquery', 'mustache', 'header.mask', 'header.data', 'header.analytics', 'hoverIntent'],
      (jQuery, Mustache, headerMask, headerData, headerAnalytics) => factory(root, jQuery, Mustache, headerMask, headerData, headerAnalytics),
    );
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.homeDecorFurnitureFlyout = factory(root, jQuery, Mustache, THD.HFApp.data, THD.HFApp.analytics, $.hoverIntent);
    /* eslint-enable */
  }
}(this, (root, $, Mustache, headerMask, headerData, headerAnalytics) => {
  const homeDecorFurnitureFlyout = {};
  let homeDecorFurnitureData = null;
  const privateMethods = {};

  privateMethods.templates = {};

  privateMethods.templates.subnav =
    '<ul class="homeDecorFurnitureFlyout__list">' +
    '    {{#aisles}} ' +
    '        <li>' +
    '            <a class="homeDecorFurnitureFlyout__link" ' +
    '               href="{{link}}" ' +
    '               id="{{id}}">' +
    '                {{name}} ' +
    '            </a>' +
    '        </li>' +
    '    {{/aisles}} ' +
    '</ul>';

  privateMethods.templates.staticSubnav =
    '<ul class="homeDecorFurnitureFlyout__list">' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/b/Home-Decor/N-5yc1vZas6p" ' +
    '           id="home-decor">Home Decor</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/b/Furniture/N-5yc1vZc7pc" ' +
    '           id="furniture">Furniture</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/b/Kitchen-Kitchenware/N-5yc1vZaqzo" ' +
    '           id="kitchen-and-dining">Kitchen &amp; Dining</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/b/Home-Decor-Bedding-Bath/N-5yc1vZci04" ' +
    '           id="bedding-and-bath">Bedding &amp; Bath</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/b/Lighting/N-5yc1vZbvn5" ' +
    '           id="lighting">Lighting</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/b/Window-Treatments/N-5yc1vZar4w" ' +
    '           id="window-treatments">Window Treatments</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/room" ' +
    '           id="shop-by-room">Shop by Room</a>' +
    '    </li>' +
    '    <li>' +
    '        <a class="homeDecorFurnitureFlyout__link" ' +
    '           href="//www.homedepot.com/c/Savings_Center" ' +
    '           id="savings">Savings</a>' +
    '    </li>' +
    '</ul>';

  privateMethods.templates.columnarMenu =
    '{{#columns}} ' +
    '    <div class="homeDecorFurnitureFlyout__column {{#hero}}homeDecorFurnitureFlyout__column--hero{{/hero}} {{#showSeparator}}homeDecorFurnitureFlyout__column--separator{{/showSeparator}}">' +
    '        {{#hero}}' +
    '         <div class="homeDecorFurnitureFlyout__hero">' +
    '           <a class="homeDecorFurnitureFlyout__hero-banner homeDecorFurnitureFlyout__endpoint" href="{{hero.shopAllLink}}" style="color: {{hero.fontColor}}; background-color: {{hero.bannerBackgroundColor}}">' +
    '             <span class="homeDecorFurnitureFlyout__hero-banner-name">{{hero.name}}</span>' +
    '             <span class="homeDecorFurnitureFlyout__hero-banner-shop">{{hero.shopAll}}</span>' +
    '           </a>' +
    '           <a class="homeDecorFurnitureFlyout__hero-image homeDecorFurnitureFlyout__endpoint" href="{{hero.bannerLink}}" style="background-image:url({{hero.bannerImageURL}});">' +
    '           </a>' +
    '         </div>' +
    '        {{/hero}}' +
    '        {{#bays}} ' +
    '            <div class="homeDecorFurnitureFlyout__bay {{#hero}}homeDecorFurnitureFlyout__bay--hero{{/hero}} {{#inlineImage}}homeDecorFurnitureFlyout__bay--inline{{/inlineImage}}">' +
    '                {{#bayLink}} ' +
    '                    <div class="homeDecorFurnitureFlyout__bayName">' +
    '                        <a class="homeDecorFurnitureFlyout__endpoint" href="{{bayLink}}">' +
    '                            {{bayName}} ' +
    '                        </a>' +
    '                    </div>' +
    '                {{/bayLink}} ' +
    '                {{^bayLink}} ' +
    '                    <div class="homeDecorFurnitureFlyout__bayName {{#hero}}homeDecorFurnitureFlyout__bayName--hero{{/hero}}">' +
    '                        {{bayName}} ' +
    '                    </div>' +
    '                {{/bayLink}} ' +
    '                <div class="homeDecorFurnitureFlyout__columns {{^featuredBrands}}homeDecorFurnitureFlyout__columns--hidden{{/featuredBrands}}">' +
    '                {{#featuredBrands}}' +
    '                  <div class="homeDecorFurnitureFlyout__shelf-column">' +
    '                    {{#columns}}' +
    '                      <div class="{{^promotional}}homeDecorFurnitureFlyout__shelf-brands{{/promotional}}{{#promotional}}homeDecorFurnitureFlyout__shelf-promos{{/promotional}}">' +
    '                      {{#imageURL}}' +
    '                        <a href="{{shelfLink}}" class="homeDecorFurnitureFlyout__endpoint" style="background-image:url({{imageURL}})" title="{{shelfName}}"/>' +
    '                      {{/imageURL}}' +
    '                      {{^imageURL}}' +
    '                        <a class="homeDecorFurnitureFlyout__endpoint" href="{{shelfLink}}">' +
    '                          {{shelfName}} ' +
    '                        </a>' +
    '                      {{/imageURL}}' +
    '                      </div>' +
    '                    {{/columns}}' +
    '                  </div>' +
    '                {{/featuredBrands}}' +
    '                </div>' +
    '                {{^featuredBrands}}' +
    '                  {{#shelves}} ' +
    '                      <div class="homeDecorFurnitureFlyout__shelf">' +
    '                          {{#shelfLink}} ' +
    '                              <a class="homeDecorFurnitureFlyout__endpoint" href="{{shelfLink}}">' +
    '                                {{shelfName}} ' +
    '                              </a>' +
    '                          {{/shelfLink}} ' +
    '                          {{^shelfLink}} ' +
    '                              {{shelfName}} ' +
    '                          {{/shelfLink}} ' +
    '                      </div>' +
    '                  {{/shelves}} ' +
    '                {{/featuredBrands}}' +
    '                {{#imageURL}} ' +
    '                    <div class="homeDecorFurnitureFlyout__bayImage">' +
    '                        <a class="homeDecorFurnitureFlyout__endpoint homeDecorFurnitureFlyout__endpoint--image" ' +
    '                           style="background-image:url({{imageURL}})" ' +
    '                           href="{{bayLink}}" ' +
    '                           id="catalog image"></a>' +
    '                    </div>' +
    '                {{/imageURL}} ' +
    '            </div>' +
    '        {{/bays}} ' +
    '    </div>' +
    '{{/columns}}';

  privateMethods.templates.collageMenu =
    '<div class="homeDecorFurnitureFlyout__shopByRoom">' +
    '    {{#bays}} ' +
    '        <div class="homeDecorFurnitureFlyout__room">' +
    '            <a class="homeDecorFurnitureFlyout__endpoint homeDecorFurnitureFlyout__roomImage homeDecorFurnitureFlyout__endpoint--image" ' +
    '               style="background-image:url({{imageURL}})" ' +
    '               href="{{link}}" ' +
    '               id="{{roomName}}"></a>' +
    '            <a class="homeDecorFurnitureFlyout__endpoint" href="{{link}}">' +
    '                {{roomName}} ' +
    '            </a>' +
    '        </div>' +
    '    {{/bays}} ' +
    '</div>';

  privateMethods.analyticsSelectedAisle = null;
  privateMethods.breadcrumbAisleElement = null;

  privateMethods.clearUrl = function _clearUrl(urlString) {
    // Need to ensure this value isn't just a placeholder
    // if it is, we need to remove the string so Mustache can tell.
    return urlString === '#' ? '' : urlString;
  };

  privateMethods.determineAisle = function _determineAisle() {
    function highlightBreadcrumbAisleElement(breadcrumbs) {
      privateMethods.breadcrumbAisleElement = null;

      breadcrumbs.forEach((breadcrumb) => {
        const breadcrumbNValue = privateMethods.generateBreadcrumbNValue(breadcrumb);

        if (homeDecorFurnitureData && homeDecorFurnitureData.aisles) {
          const filteredAisles =
              homeDecorFurnitureData.aisles
                  .filter(aisle => aisle.link && aisle.link.indexOf(breadcrumbNValue) !== -1);
          if (filteredAisles.length === 1) {
            privateMethods.breadcrumbAisleElement = $(`.homeDecorFurnitureFlyout__link#${filteredAisles[0].id}`);
          }
        }
      });

      if (privateMethods.breadcrumbAisleElement === null) {
        breadcrumbs.forEach((breadcrumb) => {
          const breadcrumbNValue = privateMethods.generateBreadcrumbNValue(breadcrumb);

          if (homeDecorFurnitureData && homeDecorFurnitureData.aisles) {
            homeDecorFurnitureData.aisles.forEach((aisle) => {
              if (aisle && aisle.bayNValues) {
                const filteredBays =
                    aisle.bayNValues.filter(bayNValue => bayNValue === breadcrumbNValue);
                if (filteredBays.length === 1) {
                  privateMethods.breadcrumbAisleElement = $(`.homeDecorFurnitureFlyout__link#${aisle.id}`);
                }
              }
            });
          }
        });
      }

      privateMethods.highlightAisle('.homeDecorFurnitureFlyout__subnav', privateMethods.breadcrumbAisleElement);
    }

    if (headerData.checkEventBus()) {
      const EVENT_BUS = window.THD_EVENT_BUS || window.LIFE_CYCLE_EVENT_BUS;

      EVENT_BUS.lifeCycle.on('breadcrumbs.ready', (data) => {
        if (!data || !data.output) return;
        const breadcrumbs = data.output.crumbs;

        if (breadcrumbs) {
          highlightBreadcrumbAisleElement(breadcrumbs);
        }
      });

      EVENT_BUS.on('@@HDHOME/PAGE_LOAD', (data) => {
        if (!data || !data.output) return;
        const breadcrumbs = data.output.breadCrumbs ?
          data.output.breadCrumbs :
          data.output.response.breadCrumbs;

        if (breadcrumbs) {
          highlightBreadcrumbAisleElement(breadcrumbs);
        }
      });
    }
  };

  privateMethods.generateBreadcrumbNValue = function _generateBreadcrumbNValue(breadcrumb) {
    let breadcrumbNValue = null;
    if (breadcrumb && breadcrumb.url && typeof breadcrumb?.url === 'string') {
      const splitURL = (breadcrumb.url || '').split('/');
      breadcrumbNValue = splitURL.filter(part => part.indexOf('N-') !== -1)[0];
    }
    return breadcrumbNValue;
  };

  privateMethods.getFlyoutData = function getFlyoutData() {
    /* eslint-disable */
    var flyoutDataRetrieved = $.Deferred();
    /* eslint-enable */

    flyoutDataRetrieved.then(privateMethods.renderSubnav);

    if (!homeDecorFurnitureData) {
      // const jsonPath = '../../test/test_homedecor_heroes.json';
      // const responseDataPromise = $.getJSON(jsonPath);
      const jsonPath = '/hdus/en_US/DTCCOMNEW/fetch/homeDecorFurnitureFlyout.json';
      const responseDataPromise = headerData.getEtchJSON(jsonPath);

      responseDataPromise
        .then((jsonResponse) => {
          privateMethods.getFlyoutDataSuccess(jsonResponse);
          flyoutDataRetrieved.resolve();
        }, privateMethods.getFlyoutDataError());
    } else {
      flyoutDataRetrieved.resolve();
    }
  };

  privateMethods.getFlyoutDataError = function _getFlyoutDataError() {
    $('.homeDecorFurnitureFlyout__subnav').html(privateMethods.templates.staticSubnav);
  };

  function getBayNValues(columns) {
    const nValues = columns.map((column) => {
      const { bays = [] } = column;
      return bays.map((bay) => {
        const { link = '' } = bay;
        const nIndex = link.indexOf('N-');
        if (nIndex < 0) {
          return null;
        }
        return link.slice(nIndex);
      }).filter(b => !!b);
    });
    return [].concat.apply([], nValues);
  }

  function processShelves(shelves) {
    return shelves.map((shelf) => {
      const { name, link } = shelf;
      return Object.assign(shelf, { shelfName: name, shelfLink: link });
    });
  }

  /** *
   * Aisles represent each "Tab" of the subnav bar
   * @param aisle
   * @returns {{name: string, id: string, bayNValues: [], columns: [], link: string}}
   */
  function processAisle(aisle) {
    // Shop-by-room has bays at the aisle level :|
    const {
      bays = [], columns = [], id, link = '', name
    } = aisle;
    const bayNValues = getBayNValues(columns);
    const processedAisle = { bayNValues, id, name };
    processedAisle.link = link.replace('https:', '');

    if (columns.length) {
      processedAisle.columns = columns.map(column => processColumn(aisle, column));
    } else if (bays.length && name === 'Shop by Room') {
      processedAisle.bays = bays.map(room => Object.assign(room, { roomName: room.name }));
    }
    return processedAisle;
  }

  privateMethods.processBay = function _processBay(bay, hero) {
    const { link, name, shelves = [] } = bay;
    const processedShelves = processShelves(shelves);
    const featuredBrands = privateMethods.processBrands(bay, hero);
    const processedBay = Object.assign(bay, { bayName: name });

    // TODO: just include these
    if (link) {
      processedBay.bayLink = link;
    }

    if (processedShelves.length) {
      processedBay.shelves = processedShelves;
    }

    if (featuredBrands.length) {
      processedBay.featuredBrands = featuredBrands;
    }
    return processedBay;
  };

  function chunkArray(toChunkArray, columnCount) {
    if (columnCount < 2) {
      return [toChunkArray];
    }

    const arrayLength = toChunkArray.length;
    const chunks = [];
    let i = 0;
    let size;

    if (arrayLength % columnCount === 0) {
      size = Math.floor(arrayLength / columnCount);
      while (i < arrayLength) {
        chunks.push(toChunkArray.slice(i, i += size));
      }
    } else {
      while (i < arrayLength) {
        size = Math.ceil((arrayLength - i) / columnCount--);
        chunks.push(toChunkArray.slice(i, i += size));
      }
    }
    return chunks;
  }

  /**
   * Brands will be displayed in 3 columns when a hero is present.
   * Promotional blocks take up the entire column however,
   * they can co-exist with the other column types so it's easiest
   * to structure the data in column format and render that.
   * @param bay
   * @param hero
   * @returns {Array}
   */
  privateMethods.processBrands = function _processBrands(bay, hero) {
    const { name, shelves = [] } = bay;
    const promotionals = shelves.filter(shelf => shelf.promotional);
    if (!(hero && /Brands/i.test(name))) {
      return [];
    }

    const brands = promotionals.map(promo => ({
      columns: [promo],
      promotional: true
    }));
    const nonPromos = shelves.filter(shelf => !shelf.promotional);
    const availableColumns = 3 - promotionals.length;
    const chunks = chunkArray(nonPromos, availableColumns);
    chunks.forEach((chunk) => {
      brands.push({ columns: chunk, promotional: false });
    });

    return brands.slice(0, Math.min(brands.length, 3));
  };

  function processColumn(aisle, column) {
    const { bays = [], hero } = column;
    const processedBays = bays.map(bay => privateMethods.processBay(bay, hero));
    if (hero) {
      hero.bannerBackgroundColor = hero.bannerBackgroundColor || '#f2f2f1';
      hero.fontColor = hero.fontColor || '#333';
      hero.shopAll = hero.shopAll || 'Shop All';
    }
    const processedColumn = Object.assign({}, column);
    if (processedBays.length) {
      processedColumn.bays = processedBays;
    }
    return processedColumn;
  }

  privateMethods.addSeparators = function _addSeparators(aisle) {
    const updatedAisle = Object.assign({}, aisle);
    const { columns = [] } = updatedAisle;
    const separatedColumns = columns.map((column) => {
      const { bays = [], hero } = column;
      const hasImage = bays.filter(bay => bay.imageURL).length;
      const separatedColumn = Object.assign({}, column);
      separatedColumn.showSeparator = !(bays.length === 0 || hero || hasImage);
      return separatedColumn;
    });
    if (separatedColumns.length) {
      updatedAisle.columns = separatedColumns;
    }
    return updatedAisle;
  };

  privateMethods.getFlyoutDataSuccess = function _getFlyoutDataSuccess(jsonResponse) {
    homeDecorFurnitureData = jsonResponse;
    const { aisles } = homeDecorFurnitureData;

    // Process all elements so Mustache can understand
    if (aisles) {
      homeDecorFurnitureData.aisles = aisles.map(aisle => processAisle(aisle));
    }
    return homeDecorFurnitureData;
  };

  privateMethods.highlightAisle = function _highlightAisle($menu, $item) {
    if ($menu) {
      $('.homeDecorFurnitureFlyout__link--active', $menu).removeClass('homeDecorFurnitureFlyout__link--active');
    }
    if ($item) {
      $item.addClass('homeDecorFurnitureFlyout__link--active');
    }
  };

  privateMethods.interactWithAisle = function _interactWithAisle(event) {
    const $this = $(event.target);

    if (event.type === 'hoverintent' || event.type === 'touchend') {
      privateMethods.stopTouchHandling(event);
      privateMethods.highlightAisle('.homeDecorFurnitureFlyout__subnav', $this);
      privateMethods.renderMenu($this.text().trim());
    } else {
      privateMethods.analyticsSelectedAisle = $this.text().toLowerCase().replace('&', 'and').trim();
      privateMethods.logInteractionForL2();
    }
  };

  privateMethods.logEventPath = function _logEventPath(type, pathArray) {
    // Get header at the beginning, then join them all
    const level1 = 'home decor and furniture';
    return headerAnalytics.logEvent(type, ['header', level1].concat(pathArray).join('>'));
  };

  privateMethods.logInteractionForL2 = function _logInteractionForL2() {
    // L2 is an aisle in the subnav
    const level2 = privateMethods.analyticsSelectedAisle;
    return privateMethods.logEventPath('header click', [level2]);
  };

  privateMethods.logInteractionForL3 = function _logInteractionForL3(event) {
    // L3 is either a bay, shelf, or image in the menu
    const level2 = privateMethods.analyticsSelectedAisle;

    // if the user clicked on a bay or a shelf, then use the corresponding text
    // else the user clicked on an image which is either a room or a catalog
    // for an image, use the associated id
    let level3 = null;
    let featuredBrand = null;

    if ($(this).text()) {
      if ($(this).hasClass('homeDecorFurnitureFlyout__hero-image')) {
        level3 = `${level2} image`;
      } else {
        level3 = $(this).text().toLowerCase().replace('&', 'and')
          .trim();
      }
    } else if ($(this).context && $(this).context.id) {
      level3 = $(this).context.id.toLowerCase().replace('&', 'and').trim();
    } else if ($(this).attr('title')) {
      level3 = 'featured brands';
      featuredBrand = $(this).attr('title').toLowerCase().replace('&', 'and')
        .trim();
    }

    if (level3 && level3.includes('shop all')) {
      level3 = level2.trim().concat(' shop all');
    }

    if (featuredBrand) {
      privateMethods.logEventPath('header click', [level2, level3, featuredBrand]);
    } else {
      privateMethods.logEventPath('header click', [level2, level3]);
    }

    window.location.href = event.currentTarget.href;
  };

  privateMethods.registerHoverIntent = function _registerHoverIntent(selector) {
    $(document).hoverIntent({
      selector,
      over: privateMethods.triggerHoverIntent,
      out: $.noop,
    });
  };

  privateMethods.renderMenu = function _renderMenu(aisleName) {
    let reRenderMenu = false;
    if (privateMethods.analyticsSelectedAisle && privateMethods.analyticsSelectedAisle === 'shop by room') {
      reRenderMenu = true;
    }

    if (homeDecorFurnitureData) {
      privateMethods.analyticsSelectedAisle = aisleName.toLowerCase().replace('&', 'and').trim();

      const menuSelector = $('.homeDecorFurnitureFlyout__menu');

      let htmlContent;

      let aisle = {};
      homeDecorFurnitureData.aisles.forEach((element) => {
        if (element.name === aisleName) {
          aisle = element;
        }
      });

      if (aisleName === 'Shop by Room') {
        htmlContent = Mustache.render(privateMethods.templates.collageMenu, aisle);
      } else {
        privateMethods.addSeparators(aisle);
        htmlContent = Mustache.render(privateMethods.templates.columnarMenu, aisle);
      }

      $('.homeDecorFurnitureSubnav__subnav-wrapper').addClass('homeDecorFurnitureSubnav--open');

      headerMask.show();

      menuSelector.html(htmlContent);
      menuSelector.addClass('homeDecorFurnitureFlyout__menu--open');
      $('.homeDecorFurnitureFlyout__menu-wrapper').addClass('homeDecorFurnitureFlyout--open');

      if (reRenderMenu && aisle && aisle.name) {
        if (aisle.name.toLowerCase().trim() === 'shop by room') {
          privateMethods.reRenderPostShopByRoomMenu(privateMethods.templates.collageMenu, aisle);
        } else {
          privateMethods.reRenderPostShopByRoomMenu(privateMethods.templates.columnarMenu, aisle);
        }
      }
    }
  };

  privateMethods.renderSubnav = function _renderSubnav() {
    if (homeDecorFurnitureData) {
      $('.homeDecorFurnitureFlyout__subnav').html(Mustache.render(privateMethods.templates.subnav, homeDecorFurnitureData));
      privateMethods.determineAisle();
    }
  };

  // eslint-disable-next-line max-len
  privateMethods.reRenderPostShopByRoomMenu = function _reRenderPostShopByRoomMenu(template, aisle) {
    const menuHTMLContent = Mustache.render(template, aisle);
    $('.homeDecorFurnitureFlyout__menu').html(menuHTMLContent);
  };

  privateMethods.setMenuState = function _setMenuState(state) {
    $('.homeDecorFurnitureFlyout')
      .toggleClass('homeDecorFurnitureFlyout', (state === 'bay'));
  };

  privateMethods.stopTouchHandling = function _stopTouchHandling(event) {
    // If this is a touch interaction
    if (event.type === 'touchend') {
      // Prevent the normal behavior
      event.preventDefault();
      event.stopPropagation();
    }
  };

  privateMethods.triggerHoverIntent = function _triggerHoverIntent() {
    $(this).trigger({
      type: 'hoverintent',
      target: this,
    });
  };

  // bind to all the necessary elements
  privateMethods.initialize = function initialize() {
    $(document)
      .on('click touchend hoverintent', '.Header3 .ShoppingLinks__link[data-id="homeDecorFurniture"]', privateMethods.getFlyoutData)
      .hoverIntent({
        selector: '.Header3 .ShoppingLinks__link[data-id="homeDecorFurniture"]',
        over: privateMethods.triggerHoverIntent,
        out: $.noop,
      });
    $(document)
      .on('click touchend hoverintent', '.homeDecorFurnitureFlyout__subnav .homeDecorFurnitureFlyout__link', privateMethods.interactWithAisle)
      .on('click touchend', '.homeDecorFurnitureFlyout__menu .homeDecorFurnitureFlyout__endpoint', privateMethods.logInteractionForL3);

    privateMethods.registerHoverIntent('.homeDecorFurnitureFlyout__link');
  };

  /*
   * Public Methods
   */

  homeDecorFurnitureFlyout.init = function init() {
    privateMethods.initialize();
    privateMethods.getFlyoutData();

    headerData.init();
    headerAnalytics.init();

    return this;
  };

  homeDecorFurnitureFlyout.getBreadcrumbAisleElement = function getBreadcrumbAisleElement() {
    return privateMethods.breadcrumbAisleElement;
  };

  homeDecorFurnitureFlyout.getHomeDecorFurnitureData = function getHomeDecorFurnitureData() {
    return homeDecorFurnitureData;
  };

  homeDecorFurnitureFlyout.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  homeDecorFurnitureFlyout.highlightSubnavAisle = function highlightSubnavAisle(menu, item) {
    privateMethods.highlightAisle(menu, item);
  };

  return homeDecorFurnitureFlyout;
}));
