/* globals define */
/*
  Purpose:
    - UI controller for the flyout.

  Dependencies
    - Mustache
    - jQuery
    - THD Namespace
    - body-scroll-lock
*/
(function closure(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(
      'header.simpleflyout', ['jquery', 'mustache', 'body-scroll-lock', 'header.data', 'header.analytics', 'header.mask', 'hfConfig', 'thd-customer', 'text!homeDecorFurnitureMobileFlyoutLevel1Template', 'text!homeDecorFurnitureMobileFlyoutLevel2Template', 'text!homeDecorFurnitureMobileFlyoutLevel3Template', 'header.specialsflyout', 'text!specialsSimpleFlyoutLevel1Tpl'],
      (jQuery, Mustache, bodyScrollLock, headerData, headerAnalytics, headerMask, hfConfig, thdcustomer, homeDecorFurnitureMobileFlyoutLevel1Template, homeDecorFurnitureMobileFlyoutLevel2Template, homeDecorFurnitureMobileFlyoutLevel3Template, specialsfFlyout, simpleflyoutLevel1Template) => factory(root, jQuery, Mustache, bodyScrollLock, headerData, headerAnalytics, headerMask, hfConfig, thdcustomer, homeDecorFurnitureMobileFlyoutLevel1Template, homeDecorFurnitureMobileFlyoutLevel2Template, homeDecorFurnitureMobileFlyoutLevel3Template, specialsfFlyout, simpleflyoutLevel1Template)
    );
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.flyout = factory(root, jQuery, Mustache, THD.HFApp.data, THD.HFApp.analytics);
    /* eslint-enable */
  }
}(this, (root, $, Mustache, bodyScrollLock, headerData, headerAnalytics, headerMask, hfConfig, thdcustomer, homeDecorFurnitureMobileFlyoutLevel1Template, homeDecorFurnitureMobileFlyoutLevel2Template, homeDecorFurnitureMobileFlyoutLevel3Template, specialsfFlyout, simpleflyoutLevel1Template) => {
  const flyout = {};

  const privateMethods = {};
  const privateData = {};

  let homeDecorFurnitureData = {};
  let diyProjectsIdeasData = {};
  let homeServicesData = {};
  let specialsOffersData = {};

  let l1Name;
  let l2Name;

  let homeDecorFurnitureRecentlyViewedCategories = [];
  let persistentAisle = null;
  let persistentBay = null;

  privateMethods.templates = {};

  // constructDIYProjectsIdeasFlyoutLevel1
  // constructHomeServicesFlyoutLevel1
  // The id contains level 2 even though this is level 1
  privateMethods.templates.diyAndHomeServicesLevel1 =
    '<div id="diyhslevel2" class="diyandhs">' +
    '  <span class="SimpleFlyout__header">' +
    '    {{name}} ' +
    '  </span>' +
    '  <div class="SimpleFlyout__divider"></div>' +
    '  <ul class="SimpleFlyout__list">' +
    '    {{#links}} ' +
    '      {{#hidel2level}} ' +
    '        {{#sublinks}} ' +
    '          {{^hidemobile}} ' +
    '            <li class="SimpleFlyout__listItem">' +
    '              <a data-type="direct" ' +
    '                 class="SimpleFlyout__link" ' +
    '                 href="{{link}}" ' +
    '                 title="{{{title}}}">' +
    '                {{{title}}} ' +
    '              </a>' +
    '            </li>' +
    '          {{/hidemobile}} ' +
    '        {{/sublinks}} ' +
    '      {{/hidel2level}} ' +
    '      {{^hidel2level}} ' +
    '        <li class="SimpleFlyout__listItem">' +
    '          <a class="{{linkClassName}}__link"  ' +
    '             href="{{link}}"  ' +
    '             title="{{{title}}}">' +
    '            {{{title}}} ' +
    '          </a>' +
    '        </li>' +
    '      {{/hidel2level}} ' +
    '    {{/links}} ' +
    '    {{#viewAllLink}} ' +
    '      <li class="SimpleFlyout__listItem">' +
    '        <a data-type="direct" ' +
    '           class="SimpleFlyout__link" ' +
    '           href="{{viewAllLink}}" ' +
    '           title="View All">' +
    '          View All {{name}} ' +
    '        </a>' +
    '      </li>' +
    '    {{/viewAllLink}} ' +
    '  </ul>' +
    '</div>';

  // diyHomeServicesHelper
  privateMethods.templates.diyAndHomeServicesLevel3 =
    '<span class="SimpleFlyout__header">' +
    '  {{name}} ' +
    '</span>' +
    '<div class="SimpleFlyout__divider"></div>' +
    '<ul class="SimpleFlyout__list">' +
    '  {{#sublinks}} ' +
    '    <li class="SimpleFlyout__listItem">' +
    '      <a data-type="direct" ' +
    '         class="SimpleFlyout__link" ' +
    '         href="{{link}}" ' +
    '         title="{{{title}}}">' +
    '        {{{title}}} ' +
    '      </a>' +
    '    </li>' +
    '  {{/sublinks}} ' +
    '  {{#viewAllLink}} ' +
    '    <li class="SimpleFlyout__listItem">' +
    '      <a data-type="direct" ' +
    '         class="SimpleFlyout__link" ' +
    '         href="{{viewAllLink}}" ' +
    '         title="View All">' +
    '        View All {{name}} ' +
    '      </a>' +
    '    </li>' +
    '  {{/viewAllLink}} ' +
    '</ul>';

  // openSimpleMenuLevel2
  // openSimpleMenuLevel3
  privateMethods.templates.simpleList =
    '{{#allLink}} ' +
    '  <a href="{{allLink}}">' +
    '    <span class="SimpleFlyout__header">' +
    '      {{name}} ' +
    '    </span>' +
    '  </a>' +
    '{{/allLink}} ' +
    '{{^allLink}} ' +
    '  <span class="SimpleFlyout__header">' +
    '    {{name}} ' +
    '  </span>' +
    '{{/allLink}} ' +
    '<div class="SimpleFlyout__divider"></div>' +
    '<ul class="SimpleFlyout__list">' +
    '  {{#links}} ' +
    '    <li class="SimpleFlyout__listItem">' +
    '      <a class="SimpleFlyout__link" href="{{url}}" title="{{name}}">' +
    '        {{name}} ' +
    '      </a>' +
    '    </li>' +
    '  {{/links}} ' +
    '  {{#allLink}} ' +
    '    <li class="SimpleFlyout__listItem">' +
    '      <a data-type="direct" class="SimpleFlyout__link" href="{{allLink}}" title="View All">' +
    '        View All {{name}} ' +
    '      </a>' +
    '    </li>' +
    '  {{/allLink}} ' +
    '</ul>';

  // renderL1Categories
  privateMethods.templates.level1Flyout =
    '{{#l1}} ' +
    '  {{^hideL1}} ' +
    '    <li class="SimpleFlyout__listItem">' +
    '      <a href="{{link}}" ' +
    '         class="SimpleFlyout__link{{#activeHolidayClass}} SimpleFlyout__link--{{activeHolidayClass}}{{/activeHolidayClass}}" ' +
    '         data-level="{{name}}" ' +
    '         data-image="{{feature.image}}" ' +
    '         data-title="{{feature.title}}" ' +
    '         data-cta="{{feature.cta}}" ' +
    '         data-link="{{feature.link}}" ' +
    '         title="{{name}}">' +
    '        {{name}} ' +
    '      </a>' +
    '    </li>' +
    '  {{/hideL1}} ' +
    '{{/l1}}';

  privateData.homeDecorFurnitureSelectedLevels = [];
  privateData.homeServicesSelectedLevels = [];
  privateData.specialsSelectedLevels = [];

  /*
   * Private Methods
   */

  /**
   * Captures the path traversed by the user on click of the headers in L2 and L3.
   * This is used for analytics. And logs the path
   */

  privateData.pathCaptured = false;

  privateMethods.capturePath = function _capturePath() {
    if (!privateData.pathCaptured) {
      const $this = $(this);

      if ($this.eq(0).hasClass('hdfMobile')) {
        // Home Decor, Furniture & Kitchenware
        privateData.homeDecorFurnitureSelectedLevels.push($this.text());
        privateMethods.logHomeDecorFurniturePath('header click');
      } else {
        // All Departments
        const path = ['all departments', privateData.selectedLevel1];
        privateData.selectedLevel2 !== undefined && path.push(privateData.selectedLevel2);
        path.push($this.text());
        privateMethods.logEventPath('header click', path);
      }

      privateData.pathCaptured = true;
    } else {
      privateData.pathCaptured = false;
    }
  };

  privateMethods.clearURL = function _clearURL(urlString) {
    // Need to ensure this value isn't just a placeholder
    // if it is, we need to remove the string so Mustache can tell.
    return urlString === '#' ? '' : urlString;
  };

  privateMethods.closeOnSearchOpen = function _closeOnSearchOpen(event, isOpen) {
    if (isOpen) {
      privateMethods.showSimpleFlyout(event, false);
      $(document).trigger('toggleMyAccountDrawer', false);
    }
  };

  privateMethods.constructDIYProjectsIdeasFlyoutLevel1 = function _constructDIYProjectsIdeasFlyoutLevel1(event, response) {
    const panelTitle = $(event.target).text();
    const viewAllURL = $(event.target).attr('href');
    let htmlContent;

    diyProjectsIdeasData = $.extend(true, response);
    diyProjectsIdeasData.diyflyout.name = panelTitle;
    diyProjectsIdeasData.diyflyout.linkClassName = 'diy';
    diyProjectsIdeasData.diyflyout.viewAllLink = viewAllURL;
    htmlContent = Mustache.render(privateMethods.templates.diyAndHomeServicesLevel1, diyProjectsIdeasData.diyflyout);
    $('#simplePanelLevel1 span, #simplePanelLevel1 div.SimpleFlyout__divider:eq(1)').hide();
    $('#simplePanelLevel1').append(htmlContent);
  };

  privateMethods.constructHomeDecorFurnitureFlyoutLevel1 = function _constructHomeDecorFurnitureFlyoutLevel1(event, response) {
    const panelTitle = 'Home Decor, Furniture & Kitchenware';
    const previousBackPanel = $('.SimpleFlyout__back');

    homeDecorFurnitureData = response;
    homeDecorFurnitureData.name = panelTitle;
    homeDecorFurnitureRecentlyViewedCategories = privateMethods.getRecentlyViewedCategories();

    if (homeDecorFurnitureRecentlyViewedCategories !== null
      && homeDecorFurnitureRecentlyViewedCategories.length > 0) {
      homeDecorFurnitureData.hasCategories = true;
      homeDecorFurnitureData.categories = homeDecorFurnitureRecentlyViewedCategories;
    }

    const htmlContent = Mustache.render(homeDecorFurnitureMobileFlyoutLevel1Template, homeDecorFurnitureData);

    $('#simplePanelLevel1 span, #simplePanelLevel1 div.SimpleFlyout__divider:eq(1)').hide();
    $('#simplePanelLevel1').append(htmlContent);

    if (previousBackPanel[0] && previousBackPanel[0].childNodes && previousBackPanel[0].childNodes[2]) {
      previousBackPanel[0].childNodes[2].textContent = ' Main Menu';
    }

    privateMethods.showSimplePanel(1);
  };

  privateMethods.constructHomeServicesFlyoutLevel1 = function _constructHomeServicesFlyoutLevel1(event, response) {
    const panelTitle = $(event.target).text();
    const viewAllURL = $(event.target).attr('href');

    homeServicesData = $.extend(true, response);
    homeServicesData.hsflyout.name = panelTitle;
    homeServicesData.hsflyout.linkClassName = 'hs';
    homeServicesData.hsflyout.viewAllLink = viewAllURL;

    const htmlContent = Mustache.render(privateMethods.templates.diyAndHomeServicesLevel1, homeServicesData.hsflyout);

    $('#simplePanelLevel1 span, #simplePanelLevel1 div.SimpleFlyout__divider:eq(1)').hide();
    $('#simplePanelLevel1').append(htmlContent);
  };

  privateMethods.determineHomeDecorFurnitureSubnavHeader = function _determineHomeDecorFurnitureSubnavHeader() {
    const homeDecorFurnitureDataPromise = privateMethods.retrieveHomeDecorFurnitureData();

    const persistentSubnavTitleElement = $('.SimpleFlyout__subnav__title');
    let persistentSubnavTitle = 'Home Decor, Furniture & Kitchenware';

    // This function does way too much!
    function doABunchOfThingsThatNeedToBeRefactoredButEventuallySetSubNavTitle(breadcrumbs) {
      if (breadcrumbs && breadcrumbs.length > 0 && homeDecorFurnitureData && homeDecorFurnitureData.aisles) {
        const breadcrumbNValues = [];

        breadcrumbs.forEach((breadcrumb) => {
          if (breadcrumb && breadcrumb.url) {
            const splitURL = breadcrumb.url.split('/');
            const breadcrumbNValue = splitURL.filter(part => part.indexOf('N-') !== -1)[0];
            if (breadcrumbNValue) {
              breadcrumbNValues.push(breadcrumbNValue);
            }
          }
        });

        const potentialAisles = [];
        const potentialBays = [];
        let shelfFound = false;
        let bayFound = false;
        let aisleFound = false;

        homeDecorFurnitureData.aisles.forEach((aisle) => {
          aisle.aisleBulleted = false;
          if (aisle && aisle.nValue && breadcrumbNValues.indexOf(aisle.nValue) !== -1) {
            potentialAisles.push(aisle);
          }
          aisle.bays && aisle.bays.forEach((bay) => {
            bay.bayBulleted = false;
            if (bay && bay.nValue && breadcrumbNValues.indexOf(bay.nValue) !== -1) {
              potentialBays.push(bay);
            }

            bay.shelves && bay.shelves.forEach((shelf) => {
              shelf.shelfBulleted = false;
            });

            bay.shelves && bay.shelves.forEach((shelf) => {
              if (!shelfFound && shelf && shelf.nValue && breadcrumbNValues.indexOf(shelf.nValue) !== -1) {
                aisle.aisleBulleted = true;
                bay.bayBulleted = true;
                shelf.shelfBulleted = true;

                persistentAisle = aisle;
                persistentBay = bay;
                persistentSubnavTitle = bay.name;

                aisleFound = true;
                bayFound = true;
                shelfFound = true;
              }
            });
          });
        });

        if (!shelfFound) {
          if (!bayFound && potentialBays.length >= 1) {
            potentialBays[0].bayBulleted = true;
            persistentSubnavTitle = potentialBays[0].name;
            persistentBay = potentialBays[0];
            bayFound = true;

            homeDecorFurnitureData.aisles.forEach((aisle) => {
              aisle.bays.forEach((bay) => {
                if (!aisleFound && bay.bayBulleted) {
                  aisle.aisleBulleted = true;
                  persistentAisle = aisle;
                  aisleFound = true;
                }
              });
            });
          } else if (!aisleFound && potentialAisles.length >= 1) {
            potentialAisles[0].aisleBulleted = true;
            persistentSubnavTitle = potentialAisles[0].name;
            persistentAisle = potentialAisles[0];
            aisleFound = true;
          }
        }

        if (aisleFound || bayFound || shelfFound) {
          privateMethods.updateRecentlyViewedCategories(breadcrumbs[breadcrumbs.length - 1]);
        }
      }

      if (persistentSubnavTitleElement) {
        persistentSubnavTitleElement.html(persistentSubnavTitle);
      }
    }

    if (persistentSubnavTitleElement && headerData.checkEventBus()) {
      const EVENT_BUS = window.THD_EVENT_BUS || window.LIFE_CYCLE_EVENT_BUS;
      EVENT_BUS.lifeCycle.on('breadcrumbs.ready', (data) => {
        if (!data || !data.output) return;
        const breadcrumbs = data.output.crumbs;
        homeDecorFurnitureDataPromise.then(() => {
          doABunchOfThingsThatNeedToBeRefactoredButEventuallySetSubNavTitle(breadcrumbs);
        });
      });
      EVENT_BUS.on('@@HDHOME/PAGE_LOAD', (data) => {
        if (!data || !data.output) return;
        homeDecorFurnitureDataPromise.then(() => {
          const breadcrumbs = data.output.breadCrumbs ?
            data.output.breadCrumbs :
            data.output.response.breadCrumbs;
          doABunchOfThingsThatNeedToBeRefactoredButEventuallySetSubNavTitle(breadcrumbs);
        });
      });
    }

    if (persistentSubnavTitleElement) {
      persistentSubnavTitleElement.html(persistentSubnavTitle);
    }
  };

  privateMethods.diyHomeServicesHelper = function _diyHomeServicesHelper(allLinks, level1URL, level1Name) {
    if (allLinks && allLinks.length) {
      allLinks.filter((config, index) => config.title === level1Name && config.sublinks).map((currentLink) => {
        const htmlContent = Mustache.render(privateMethods.templates.diyAndHomeServicesLevel3, {
          sublinks: currentLink.sublinks,
          name: level1Name,
          viewAllLink: level1URL
        });
        $('#simplePanelLevel2 .SimpleFlyout__container').html(htmlContent);
        privateMethods.showSimplePanel(2);
      });
    }
  };

  privateMethods.fetchAisle = function _fetchAisle(level) {
    let aisle;
    homeDecorFurnitureData.aisles.forEach((element) => {
      if ((element.name || '').toLowerCase() === level.toLowerCase()) {
        aisle = element;
      }
    });
    return aisle;
  };

  privateMethods.getNValue = function _getNValue(location) {
    if (location.link) {
      return location.link.split('/').filter(part => part.indexOf('N-') !== -1)[0];
    }
    return null;
  };

  privateMethods.getRecentlyViewedCategories = function _getRecentlyViewedCategories() {
    try {
      return JSON.parse(window.localStorage.getItem('HDF_RECENTLY_VIEWED_CATEGORIES'));
    } catch (e) {
      return null;
    }
  };

  privateMethods.handleMenuLevel4 = function _handleMenuLevel4() {
    const $this = $(this);

    if (privateData.homeDecorFurnitureSelectedLevels && privateData.homeDecorFurnitureSelectedLevels[0] === 'home decor and furniture') {
      privateData.homeDecorFurnitureSelectedLevels.push($this.text());
      privateMethods.logHomeDecorFurniturePath('header click');
    } else {
      const level1Name = privateData.selectedLevel1;
      const level2Name = privateData.selectedLevel2;

      privateMethods.logEventPath('header click', ['all departments', level1Name, level2Name, $this.text()]);
    }
  };

  privateMethods.interactWithSimpleMenuLink = function _interactWithSimpleMenuLink(event) {
    const flyoutID = $(event.target).attr('data-id');

    if (flyoutID) {
      event.preventDefault();

      switch (flyoutID) {
        case 'departmentsFlyout':
          privateMethods.showAllDepartmentsFlyout(event);
          break;

        case 'homeDecorFurniture':
          privateMethods.showHomeDecorFurnitureFlyout(event);
          break;

        case 'diyProjectsIdeas':
          privateMethods.showDIYProjectsIdeasFlyout(event);
          break;

        case 'homeServices':
          privateMethods.showHomeServicesFlyout(event);
          break;

        case 'specialOffers':
          privateMethods.showSpecialsFlyout(event);
          break;

        default:
          break;
      }
    }
  };

  privateMethods.logEventPath = function _logEventPath(type, pathArray) {
    pathArray.forEach((level, index) => {
      if (level && index) {
        pathArray[index] = level.replace('&', 'and').toLowerCase().trim();
      }
    });
    // Get header at the beginning, then join them all
    return headerAnalytics.logEvent(type, ['header'].concat(pathArray).join('>'));
  };

  privateMethods.logEventPathL3Link = function _logEventPathL3Link() {
    const l3LinkText = $(this).text().replace('&', 'and').toLowerCase()
      .trim();

    const checkLevelOne = l1Name && l1Name.toLowerCase().trim();
    if (checkLevelOne !== 'home decor and furniture' && checkLevelOne !== 'all departments' && l2Name) {
      privateMethods.logEventPath('header click', [l1Name, l2Name, l3LinkText]);
    }
  };

  privateMethods.logEventPathTaskLink = function _logEventPathTaskLink() {
    const taskLinkText = $(this).text().replace('&', 'and').toLowerCase()
      .trim();
    l1Name = taskLinkText;
    privateMethods.logEventPath('header click', [taskLinkText]);
  };

  privateMethods.logFeaturedBrandsClick = function _logFeaturedBrandsClick() {
    const analyticsPathArray = [];
    privateData.homeDecorFurnitureSelectedLevels.forEach((level) => {
      analyticsPathArray.push(level.replace('&', 'and').toLowerCase().trim());
    });

    analyticsPathArray.push('featured brands');

    const featuredBrand = $(this).attr('alt')
      .replace('&', 'and')
      .toLowerCase()
      .trim();

    if (featuredBrand && featuredBrand !== '') {
      analyticsPathArray.push(featuredBrand);
    }

    return headerAnalytics.logEvent('header click', ['header'].concat(analyticsPathArray).join('>'));
  };


  privateMethods.logHeaderImageClick = function _logHeaderImageClick() {
    const analyticsPathArray = [];
    privateData.homeDecorFurnitureSelectedLevels.forEach((level) => {
      analyticsPathArray.push(level.replace('&', 'and').toLowerCase().trim());
    });

    const headerImageTitle = analyticsPathArray[analyticsPathArray.length - 1];
    analyticsPathArray.push(`${headerImageTitle} image`);

    return headerAnalytics.logEvent('header click', ['header'].concat(analyticsPathArray).join('>'));
  };

  privateMethods.logHomeDecorFurniturePath = function _logHomeDecorFurniturePath(type) {
    const analyticsPathArray = [];
    privateData.homeDecorFurnitureSelectedLevels.forEach((level) => {
      analyticsPathArray.push(level.replace('&', 'and').toLowerCase().trim());
    });
    return headerAnalytics.logEvent(type, ['header'].concat(analyticsPathArray).join('>'));
  };

  privateMethods.logRecentlyViewedCategory = function _logRecentlyViewedCategory() {
    const analyticsPathArray = [];
    privateData.homeDecorFurnitureSelectedLevels.forEach((level) => {
      analyticsPathArray.push(level.replace('&', 'and').toLowerCase().trim());
    });

    analyticsPathArray.push('recently viewed');

    const recentlyViewedCategory = this.text.replace('&', 'and').toLowerCase().trim();
    analyticsPathArray.push(recentlyViewedCategory);

    return privateMethods.logEventPath('header click', analyticsPathArray);
  };

  privateMethods.logShopAllClick = function _logShopAllClick() {
    const analyticsPathArray = [];
    privateData.homeDecorFurnitureSelectedLevels.forEach((level) => {
      analyticsPathArray.push(level.replace('&', 'and').toLowerCase().trim());
    });

    const shopAllTitle = analyticsPathArray[analyticsPathArray.length - 1];
    analyticsPathArray.push(`${shopAllTitle} shop all`);

    return headerAnalytics.logEvent('header click', ['header'].concat(analyticsPathArray).join('>'));
  };

  privateMethods.notifyMenuState = function _notifyMenuState(state) {
    // Use a namespaced event, pass along the state
    $(document).trigger('menuState', [{
      isOpen: state,
      menu: 'simple'
    }]);
  };

  privateMethods.openDiyHsMenuLevel2 = function _openDiyHsMenuLevel2(event) {
    const $this = $(this);
    const level1URL = $this.attr('href');
    const level1Name = $this.text().trim();
    const level1Class = $this.attr('class');
    let allLinks;

    if (($this.data('type') !== 'direct')) {
      event.preventDefault();
      event.stopPropagation();

      // if class is diy__link pass DIY Flyout data
      if (level1Class.indexOf('diy__link') !== -1) {
        allLinks = diyProjectsIdeasData.diyflyout.links;
      } else if (level1Class.indexOf('hs__link') !== -1) {
        privateData.homeServicesSelectedLevels.push(level1Name);
        allLinks = homeServicesData.hsflyout.links;
      }

      privateMethods.diyHomeServicesHelper(allLinks, level1URL, level1Name);
      l2Name = level1Name;
      if (!$this.hasClass('homedecor__link')) {
        privateMethods.logEventPath('header click', [l1Name, level1Name]);
      }
    } else {
      privateMethods.logEventPath('header click', [level1Name, $(event.target).text()]);
    }
  };

  privateMethods.openMenuToHomeDecorFurniture = function _openMenuToHomeDecorFurniture(event) {
    l1Name = 'home decor and furniture';
    $('.hdfMobile').remove();

    privateMethods.toggleSimpleFlyout(event);
    let htmlContent;
    const previousBackPanel = $('.SimpleFlyout__back');

    if (previousBackPanel[0] && previousBackPanel[0].childNodes && previousBackPanel[0].childNodes[2]) {
      previousBackPanel[0].childNodes[2].textContent = ' Main Menu';
    }

    if (persistentAisle === null) {
      privateMethods.showHomeDecorFurnitureFlyout(event);
    } else {
      l2Name = persistentAisle.name;
      privateData.homeDecorFurnitureSelectedLevels.push(l1Name);
      privateData.homeDecorFurnitureSelectedLevels.push(persistentAisle.name);

      if (previousBackPanel[1] && previousBackPanel[1].childNodes && previousBackPanel[1].childNodes[2]) {
        previousBackPanel[1].childNodes[2].textContent = ' Home Decor, Furniture & Kitchenware';
      }

      homeDecorFurnitureData.name = 'Home Decor, Furniture & Kitchenware';

      homeDecorFurnitureRecentlyViewedCategories = privateMethods.getRecentlyViewedCategories();
      if (homeDecorFurnitureRecentlyViewedCategories !== null
        && homeDecorFurnitureRecentlyViewedCategories.length > 0) {
        homeDecorFurnitureData.hasCategories = true;
        homeDecorFurnitureData.categories = homeDecorFurnitureRecentlyViewedCategories;
      }

      htmlContent = Mustache.render(homeDecorFurnitureMobileFlyoutLevel1Template, homeDecorFurnitureData);
      $('#simplePanelLevel1 ul, #simplePanelLevel1 span.SimpleFlyout__header').hide();
      $('#simplePanelLevel1').append(htmlContent);

      htmlContent = Mustache.render(homeDecorFurnitureMobileFlyoutLevel2Template, persistentAisle);
      $('#simplePanelLevel2 .SimpleFlyout__container').html(htmlContent);

      if (persistentBay === null) {
        privateMethods.showSimplePanel(2);
      } else {
        privateData.homeDecorFurnitureSelectedLevels.push(persistentBay.name);

        htmlContent = Mustache.render(homeDecorFurnitureMobileFlyoutLevel3Template, persistentBay);
        $('#simplePanelLevel3 .SimpleFlyout__container').html(htmlContent);

        privateMethods.showSimplePanel(3);

        if (previousBackPanel[2] && previousBackPanel[2].childNodes && previousBackPanel[2].childNodes[2]) {
          previousBackPanel[2].childNodes[2].textContent = ` ${l2Name}`;
        }
      }
    }
  };

  privateMethods.openSimpleMenuLevel2 = function _openSimpleMenuLevel2(event) {
    const $this = $(this);
    let level1Name = $this.data('name') || $this.text();
    const previousBackPanel = $('.SimpleFlyout__back');

    event.preventDefault();

    let htmlContent;

    // Sets a standard analytics value for specials & offers flyout savings event
    if ($(this).closest('a[data-id]').data('id') === 'savingsEvent') {
      level1Name = 'special savings event';
    }

    if ($this.hasClass('hdfMobile')) {
      // Home Decor, Furniture & Kitchenware
      // At this moment, the user is on panel level 1 which displays all of the aisles

      privateData.homeDecorFurnitureSelectedLevels.push(level1Name);

      homeDecorFurnitureData.name = level1Name.trim();

      const aisle = privateMethods.fetchAisle(homeDecorFurnitureData.name);

      // If the aisle has bays, move to the next panel with bays
      // Else, redirect to the aisle page
      if (aisle.hasBays) {
        homeDecorFurnitureData.bays = aisle.bays;

        htmlContent = Mustache.render(homeDecorFurnitureMobileFlyoutLevel2Template, aisle);
        $('#simplePanelLevel2 .SimpleFlyout__container').html(htmlContent);

        if (previousBackPanel[1] && previousBackPanel[1].childNodes && previousBackPanel[1].childNodes[2]) {
          previousBackPanel[1].childNodes[2].textContent = ' Home Decor, Furniture & Kitchenware';
        }

        privateMethods.showSimplePanel(2);
      } else {
        privateMethods.logHomeDecorFurniturePath('header click')
          .then(() => {
            // Go where we were going any way
            privateMethods.setLocation(aisle.link);
          });
      }
    } else {
      // All Departments
      const level1Data = $this.data();
      const level1URL = $this.attr('href');

      let departmentName = 'all departments';
      if ($this.hasClass('SimpleFlyout__link-home-decor')) {
        departmentName = 'home decor and furniture';
      } else if ($this.hasClass('SimpleFlyout__link specialOffersFlyout__l3')) {
        departmentName = 'specials and offers';
      }

      privateData.selectedLevel1 = level1Data.level;
      const requestLevel2Data = headerData.getLevel2Data(level1Data.level);

      // If it worked
      requestLevel2Data.then((level2Data) => {
        htmlContent = Mustache.render(privateMethods.templates.simpleList, {
          links: level2Data,
          allLink: privateMethods.clearURL(level1URL),
          name: level1Name
        });

        $('#simplePanelLevel2 .SimpleFlyout__container').html(htmlContent);

        if (previousBackPanel[1] && previousBackPanel[1].childNodes && previousBackPanel[1].childNodes[2]) {
          previousBackPanel[1].childNodes[2].textContent = ' Back';
        }

        privateMethods.showSimplePanel(2);
      });

      // If it failed
      requestLevel2Data.fail(() => {
        privateMethods.logEventPath('header click', [departmentName, level1Name])
          .then(() => {
            // Go where we were going any way
            privateMethods.setLocation(level1URL);
          });
      });
      requestLevel2Data.done(() => {
        privateMethods.logEventPath('header click', [departmentName, level1Name]);
      });
    }
  };

  privateMethods.openSimpleMenuLevel3 = function _openSimpleMenuLevel3(event) {
    const $this = $(this);
    const level1Name = privateData.selectedLevel1;
    const level2Name = $this.data('name') || $this.text().trim();
    privateData.selectedLevel2 = level2Name;
    const previousBackPanel = $('.SimpleFlyout__back');

    // If this is not a direct link
    if (($this.data('type') !== 'direct')) {
      event.preventDefault();

      let htmlContent;

      if ($this.hasClass('hdfMobile')) {
        // Home Decor, Furniture & Kitchenware
        const intermediateLevel = privateData.homeDecorFurnitureSelectedLevels.slice(-1)[0];
        privateData.homeDecorFurnitureSelectedLevels.push(level2Name);

        homeDecorFurnitureData.name = level2Name.trim();

        if (!homeDecorFurnitureData.bays) {
          const aisle = privateMethods.fetchAisle(intermediateLevel);
          if (aisle.hasBays) {
            homeDecorFurnitureData.bays = aisle.bays;
          }
        }

        let bay;
        homeDecorFurnitureData.bays.forEach((element) => {
          if (element.name === homeDecorFurnitureData.name) {
            bay = element;
          }
        });

        htmlContent = Mustache.render(homeDecorFurnitureMobileFlyoutLevel3Template, bay);

        $('#simplePanelLevel3 .SimpleFlyout__container').html(htmlContent);

        if (previousBackPanel[2] && previousBackPanel[2].childNodes && previousBackPanel[2].childNodes[2]) {
          previousBackPanel[2].childNodes[2].textContent = ` ${intermediateLevel}`;
        }

        privateMethods.showSimplePanel(3);
      } else {
        // All Departments
        const level2URL = $this.attr('href');

        // request the data
        const requestLevel3Data = headerData.getLevel3Data(level1Name, level2Name);

        // If it worked
        requestLevel3Data.then((level3Data) => {
          // render this thing
          htmlContent = Mustache.render(privateMethods.templates.simpleList, {
            links: level3Data,
            allLink: privateMethods.clearURL(level2URL),
            name: level2Name
          });

          // put it in the DOM
          $('#simplePanelLevel3 .SimpleFlyout__container').html(htmlContent);

          if (previousBackPanel[2] && previousBackPanel[2].childNodes && previousBackPanel[2].childNodes[2]) {
            previousBackPanel[2].childNodes[2].textContent = ' Back';
          }

          // Show the panel
          privateMethods.showSimplePanel(3);
        });

        // If it failed - could be that the index doesn't exist, or that its got no results
        requestLevel3Data.fail(() => {
          privateMethods.logEventPath(
            'header click',
            ['all departments',
              level1Name,
              level2Name
            ]
          )
            .then(() => {
              // Go where we were going any way
              window.location = level2URL;
            });
        });

        requestLevel3Data.done(() => {
          privateMethods.logEventPath('header click', ['all departments', level1Name, level2Name]);
        });
      }
    } else {
      // This is a direct link so log it now
      if ($this.hasClass('hdfMobile')) {
        let analyticsSelectedBay = level2Name;
        if (level2Name.trim() === '') {
          analyticsSelectedBay = $this[0].childNodes[1].alt
            ? `image ${$this[0].childNodes[1].alt}`
            : 'image';
        }
        privateData.homeDecorFurnitureSelectedLevels.push(analyticsSelectedBay);
        privateMethods.logHomeDecorFurniturePath('header click');
      } else if (privateData.homeServicesSelectedLevels && privateData.homeServicesSelectedLevels[0] === 'home services') {
        privateData.homeServicesSelectedLevels.push(level2Name);
      } else {
        privateMethods.logEventPath('header click', ['all departments', level1Name, level2Name]);
      }
    }
  };

  privateMethods.preventMaskClose = function _preventMaskClose(event) {
    event.stopPropagation();
  };

  privateMethods.removeB2XLinks = function _removeB2XLinks() {
    if (thdcustomer.default.isB2XCustomer) {
      $('#B2CRedirect')
        .remove();
      $('#MobiletaskLinkPro')
        .remove();
      $('#MobiletaskLinkFav')
        .remove();
      $('#headerOrderStatus')
        .remove();
      $('#MobileTasklinkList')
        .removeClass('u--hide');
      $('#FavoritesLink')
        .attr('href', 'https://' + hfConfig.nonSecureHostName + '/b2b/account/view/list/summary');
    } else {
      $('#B2XRedirect')
        .remove();
      $('#b2xheaderOrderStatus')
        .remove();
      $('#MobileTasklinkList')
        .removeClass('u--hide');
    }
  };

  privateMethods.renderL1Categories = function _renderL1Categories() {
    const requestLevel1Data = headerData.getLevel1Data();
    requestLevel1Data.then((level1Data) => {
      const htmlContent = Mustache.render(privateMethods.templates.level1Flyout, {
        l1: level1Data
      });
      $('#simplePanelLevel1 ul.SimpleFlyout__list').empty().html(htmlContent);
    });
  };

  privateMethods.retrieveHomeDecorFurnitureData = function _retrieveHomeDecorFurnitureData() {
    const jsonPath = '/hdus/en_US/DTCCOMNEW/fetch/homeDecorFurnitureMobileFlyout.json';
    const responseDataPromise = headerData.getEtchJSON(jsonPath);

    responseDataPromise.then((homeDecorFurnitureFlyoutData) => {
      privateMethods.setHomeDecorFurnitureData(homeDecorFurnitureFlyoutData);
    });

    return responseDataPromise;
  };

  privateMethods.setHomeDecorFurnitureData = function _setHomeDecorFurnitureData(homeDecorFurnitureFlyoutData) {
    homeDecorFurnitureRecentlyViewedCategories = privateMethods.getRecentlyViewedCategories();

    if (homeDecorFurnitureFlyoutData && homeDecorFurnitureFlyoutData.aisles) {
      homeDecorFurnitureFlyoutData.aisles.forEach((aisle) => {
        if (aisle.columns && aisle.columns.length !== 0) {
          aisle.nValue = privateMethods.getNValue(aisle);
          aisle.bays = [];

          aisle.columns.forEach((column) => {
            if (column.bays && column.bays.length !== 0) {
              column.bays.forEach((bay) => {
                bay.nValue = privateMethods.getNValue(bay);

                bay.hasShelves = bay.shelves && bay.shelves.length !== 0;
                if (bay.hasShelves) {
                  bay.shelves.forEach((shelf) => {
                    shelf.nValue = privateMethods.getNValue(shelf);
                  });

                  bay.categories = homeDecorFurnitureRecentlyViewedCategories;
                  if (bay.categories && bay.categories.length > 0) {
                    bay.hasCategories = true;
                    bay.shelves[bay.shelves.length - 1].seperator = true;
                  }
                }

                aisle.bays.push(bay);
              });
            }
          });
        }
        if (aisle.name === 'Shop by Room') {
          aisle.bays.forEach((bay) => {
            bay.imageURL = null;
          });
        }
        aisle.hasBays = aisle.bays && aisle.bays.length !== 0;

        if (homeDecorFurnitureRecentlyViewedCategories !== null
          && homeDecorFurnitureRecentlyViewedCategories.length > 0
          && aisle.hasBays) {
          aisle.bays.push({
            bayBulleted: false,
            categories: homeDecorFurnitureRecentlyViewedCategories,
            grid: false,
            hasCategories: true,
            hasShelves: true,
            inline: true,
            name: 'Recently Viewed Categories',
            seperator: true
          });
        }
      });
    }

    homeDecorFurnitureData = homeDecorFurnitureFlyoutData;
  };

  privateMethods.setLocation = function _setLocation(url) {
    window.location = url;
  };

  privateMethods.showAllDepartmentsFlyout = function _showAllDepartmentsFlyout(event) {
    // Do not redirect the user to href link
    if ($(event.target).data('type') !== 'direct') {
      event.preventDefault();
      $('.diyandhs').remove();
      $('.hdfMobile').remove();
      $('.specialsflyout').remove();
      $('#simplePanelLevel1 .SimpleFlyout__list').show();
      $('#simplePanelLevel1 span, #simplePanelLevel1 div.SimpleFlyout__divider:eq(1)').show();

      const previousBackPanel = $('.SimpleFlyout__back');

      if (previousBackPanel[0] && previousBackPanel[0].childNodes && previousBackPanel[0].childNodes[2]) {
        previousBackPanel[0].childNodes[2].textContent = ' Back';
      }

      privateMethods.showSimplePanel(1);
      $(document).trigger('closeHeaderTooltips');
    } else {
      // the below log event needs to be in an else block
      privateMethods.logEventPath('header click', ['all departments', $(event.target).text()]);
    }
  };

  privateMethods.showDIYProjectsIdeasFlyout = function _showDIYProjectsIdeasFlyout(event) {
    const jsonPath = '/hdus/en_US/DTCCOMNEW/fetch/diyflyout.json';
    const level1Name = $(event.target).text();
    const panelTitle = $(event.target).text();
    const previousBackPanel = $('.SimpleFlyout__back');

    // do not redirect the user to href link
    if ($(event.target).data('type') !== 'direct') {
      event.preventDefault();

      $('.diyandhs').remove();
      $('.hdfMobile').remove();
      $('#simplePanelLevel1 .SimpleFlyout__list').hide();

      // Only make a call if diyProjectsIdeasData is not available
      if (!diyProjectsIdeasData.hasOwnProperty('diyflyout')) {
        headerData.getEtchJSON(jsonPath)
          .then(privateMethods.constructDIYProjectsIdeasFlyoutLevel1.bind(null, event));
      } else {
        privateMethods.constructDIYProjectsIdeasFlyoutLevel1(event, diyProjectsIdeasData);
      }

      if (previousBackPanel) {
        if (previousBackPanel[0] && previousBackPanel[0].childNodes && previousBackPanel[0].childNodes[2]) {
          previousBackPanel[0].childNodes[2].textContent = ' Back';
        }
        if (previousBackPanel[1] && previousBackPanel[1].childNodes && previousBackPanel[1].childNodes[2]) {
          previousBackPanel[1].childNodes[2].textContent = ' Back';
        }
      }

      privateMethods.showSimplePanel(1);
      $(document).trigger('closeHeaderTooltips');
    } else { // redirect the user to href link & log the details
      privateMethods.logEventPath('header click', [level1Name, panelTitle]);
    }
  };

  privateMethods.showHomeDecorFurnitureFlyout = function _showHomeDecorFurnitureFlyout(event) {
    privateData.homeDecorFurnitureSelectedLevels.push('home decor and furniture');

    // do not redirect the user to href link
    if ($(event.target).data('type') !== 'direct') {
      event.preventDefault();

      $('.hdfMobile').remove();
      $('#simplePanelLevel1 .SimpleFlyout__list').hide();

      // Only make a call if aisles is not available
      if (!homeDecorFurnitureData.hasOwnProperty('aisles')) {
        const flyoutDataRetrieved = privateMethods.retrieveHomeDecorFurnitureData();

        flyoutDataRetrieved.then(() => {
          // homeDecorFurnitureData is a global variable that was set in retrieveHomeDecorFurnitureData()
          privateMethods.constructHomeDecorFurnitureFlyoutLevel1(event, homeDecorFurnitureData);
        });

        $(document).trigger('closeHeaderTooltips');
      } else {
        privateMethods.constructHomeDecorFurnitureFlyoutLevel1(event, homeDecorFurnitureData);
        $(document).trigger('closeHeaderTooltips');
      }
    } else { // redirect the user to href link & log the details
      const panelTitle = $(event.target).text();
      privateMethods.logEventPath('header click', [panelTitle]);
    }
  };

  privateMethods.showHomeServicesFlyout = function _showHomeServicesFlyout(event) {
    privateData.homeServicesSelectedLevels.push('home services');

    const jsonPath = '/hdus/en_US/DTCCOMNEW/fetch/hsflyout.json';
    const level1Name = $(event.target).text();
    const panelTitle = $(event.target).text();
    const previousBackPanel = $('.SimpleFlyout__back');

    // Do not redirect the user to href link
    if ($(event.target).data('type') !== 'direct') {
      event.preventDefault();
      $('.diyandhs').remove();
      $('.hdfMobile').remove();
      $('#simplePanelLevel1 .SimpleFlyout__list').hide();

      // Only make a call if homeServicesData is not available
      if (!homeServicesData.hasOwnProperty('hsFlyout')) {
        headerData.getEtchJSON(jsonPath)
          .then(privateMethods.constructHomeServicesFlyoutLevel1.bind(null, event));
      } else {
        privateMethods.constructHomeServicesFlyoutLevel1(event, homeServicesData);
      }

      if (previousBackPanel) {
        if (previousBackPanel[0] && previousBackPanel[0].childNodes && previousBackPanel[0].childNodes[2]) {
          previousBackPanel[0].childNodes[2].textContent = ' Back';
        }
        if (previousBackPanel[1] && previousBackPanel[1].childNodes && previousBackPanel[1].childNodes[2]) {
          previousBackPanel[1].childNodes[2].textContent = ' Back';
        }
      }

      privateMethods.showSimplePanel(1);
      $(document).trigger('closeHeaderTooltips');
    } else { // redirect the user to href link & log the details
      privateMethods.logEventPath('header click', [level1Name, panelTitle]);
    }
  };

  privateMethods.showSimpleFlyout = function _showSimpleFlyout(event, open) {
    const menuShown = (typeof open === 'undefined') ? true : open;
    const $button = $('#simpleMenuButton');
    // element selector for mobile flyout menu
    const menuElement = document.querySelector('#simpleFlyout');

    privateMethods.notifyMenuState(menuShown);
    // toggle the menu state
    $('#simpleFlyout').toggleClass('SimpleFlyout--closed', !menuShown);
    // Toggle the button state... its complicated
    $button.find('.SimpleMenu__close').toggleClass('SimpleMenu__close--open', menuShown);
    $button.find('.SimpleMenu__menu').toggleClass('SimpleMenu__menu--closed', menuShown);

    // reset the panel to the beginning
    privateData.homeDecorFurnitureSelectedLevels = [];
    privateData.homeServicesSelectedLevels = [];
    privateMethods.showSimplePanel(0);
    headerMask.show();

    if (menuShown) {
      const top = parseInt($('.SimpleFlyout__overlay').css('top'), 10);
      const scrollTop = $(window).scrollTop();
      const offset = $('.Header3').offset().top;
      // close the tooltips in case they are open
      $(document).trigger('closeHeaderTooltips');
      $(document).trigger('closeAllMenus');
      // show the masking layer if appropriate
      $(document).trigger('headerMask-show', menuShown);
      // disable body scroll when mobile menu is open
      $('.SimpleFlyout__overlay').css('top', `${top - scrollTop + offset}px`);
      $('#simpleFlyout').addClass('SimpleFlyout--open');
      bodyScrollLock.disableBodyScroll(menuElement);
    } else {
      $(document).trigger('headerMask-show', menuShown);
      // enable body scroll when mobile menu is closed
      $('#simpleFlyout').removeClass('SimpleFlyout--open');
      $('.SimpleFlyout__overlay').css('top', '');
      bodyScrollLock.enableBodyScroll(menuElement);
    }
  };

  privateMethods.showSimplePanel = function _showSimplePanel(level) {
    const simpleFlyout = $('#simpleFlyout');
    const thisLevel = parseInt(level, 10);

    simpleFlyout.find('.SimpleFlyout__wrapper')
      .toggleClass('SimpleFlyout__wrapper--level1', (thisLevel === 1))
      .toggleClass('SimpleFlyout__wrapper--level2', (thisLevel === 2))
      .toggleClass('SimpleFlyout__wrapper--level3', (thisLevel === 3));

    simpleFlyout.scrollTop(0);
  };

  privateMethods.simpleMenuBack = function _simpleMenuBack(event) {
    if (event && event.type === 'touchend') {
      // Prevent default to stop click firing for touch events
      event.preventDefault();
    }

    privateData.homeDecorFurnitureSelectedLevels.pop();

    // strangely, event is explicitly needed for FF but not for others...
    privateMethods.showSimplePanel($(event.target).data('level'));
  };

  privateMethods.toggleSimpleFlyout = function _toggleSimpleFlyout(event) {
    const $simpleMenu = $('#simpleFlyout');
    const simpleMenuOpen = !(!$simpleMenu.hasClass('SimpleFlyout--closed'));

    event.preventDefault();
    event.stopPropagation();

    $(document).trigger('toggleMyAccountDrawer', false);
    privateMethods.showSimpleFlyout(event, simpleMenuOpen);
  };

  privateMethods.updateRecentlyViewedCategories = function _updateRecentlyViewedCategories(breadcrumb) {
    let recentlyViewedCategories = privateMethods.getRecentlyViewedCategories();

    if (recentlyViewedCategories === null) {
      recentlyViewedCategories = [];
      recentlyViewedCategories.unshift({
        name: breadcrumb.name || breadcrumb.label,
        url: breadcrumb.url
      });
    } else {
      let categoryFound = false;
      recentlyViewedCategories.forEach((category) => {
        if (category.name === breadcrumb.name
          || category.name === breadcrumb.label
          || category.url === breadcrumb.url) {
          categoryFound = true;
        }
      });

      if (!categoryFound) {
        if (recentlyViewedCategories.length === 5) {
          recentlyViewedCategories.pop();
        }
        recentlyViewedCategories.unshift({
          name: breadcrumb.name || breadcrumb.label,
          url: breadcrumb.url
        });
      }
    }

    window.localStorage.setItem('HDF_RECENTLY_VIEWED_CATEGORIES', JSON.stringify(recentlyViewedCategories));
  };

  // Specials and offers flyout modules

  privateMethods.setSpecialsOffersData = function _setSpecialsOffersData(data) {
    specialsOffersData = data;
  };

  privateMethods.getSpecialsOffersData = function _getSpecialsOffersData() {
    return specialsOffersData;
  };

  privateMethods.constructSpecialsFlyoutLevel1 = function _constructSpecialsFlyoutLevel1(event) {
    const panelTitle = $(event.target).text();
    const { specialsflyout } = specialsOffersData;

    specialsflyout.linkClassName = 'specials';
    specialsflyout.name = panelTitle;

    const htmlContent = Mustache.render(simpleflyoutLevel1Template, specialsflyout);

    $('#simplePanelLevel1 span, #simplePanelLevel1 div.SimpleFlyout__divider:eq(1)').hide();
    $('#simplePanelLevel1').append(htmlContent);
  };

  privateMethods.showSpecialsFlyout = function _showSpecialsFlyout(event) {
    privateData.specialsSelectedLevels.push('specials offers');

    const level1Name = $(event.target).text();
    const panelTitle = $(event.target).text();
    const previousBackPanel = $('.SimpleFlyout__back');

    // Do not redirect the user to href link
    if ($(event.target).data('type') !== 'direct') {
      event.preventDefault();
      $('.specialsflyout').remove();
      $('.hdfMobile').remove();
      $(' #simplePanelLevel1 .SimpleFlyout__list').hide();


      // Only make a call if specialsOffersData is not available
      if (!specialsOffersData.specialsflyout) {
        specialsfFlyout.getFlyoutData((flyoutData) => {
          specialsOffersData = flyoutData;

          privateMethods.constructSpecialsFlyoutLevel1(event, specialsOffersData);
        });
      } else {
        privateMethods.constructSpecialsFlyoutLevel1(event, specialsOffersData);
      }
      (previousBackPanel[0].childNodes[2] || '').textContent = ' Back';
      (previousBackPanel[1].childNodes[2] || '').textContent = ' Back';
      privateMethods.showSimplePanel(1);
      $(document).trigger('closeHeaderTooltips');
    } else { // redirect the user to href link & log the details
      privateMethods.logEventPath('header click', [level1Name, panelTitle]);
    }
  };

  // bind to all the necessary elements
  privateMethods.initialize = function initialize() {
    // delegate to all the things
    privateMethods.removeB2XLinks();

    privateMethods.determineHomeDecorFurnitureSubnavHeader();

    $(document)
      .on('click touchend', '#simpleFlyout', privateMethods.preventMaskClose)
      .on('click touchend', '#simpleFlyout .SimpleFlyout__back', privateMethods.simpleMenuBack)
      .on('click touchend', '#simpleMenuButton', privateMethods.toggleSimpleFlyout)
      .on('click touchend', '#simplePanelLevel2 .SimpleFlyout__header', privateMethods.capturePath)
      .on('click touchend', '#simplePanelLevel3 .SimpleFlyout__header', privateMethods.capturePath)
      .on('click touchend', '.SimpleFlyout__subnav', privateMethods.openMenuToHomeDecorFurniture)
      .on('click', '#diyhslevel2 .diy__link, #diyhslevel2 .hs__link', privateMethods.openDiyHsMenuLevel2)
      .on('click', '#simplePanelLevel1 .SimpleFlyout__link', privateMethods.openSimpleMenuLevel2)
      .on('click', '#simplePanelLevel1 .SimpleFlyout__link--homeDecorFurniture', privateMethods.openSimpleMenuLevel2)
      .on('click', '#simplePanelLevel2 .SimpleFlyout__link', privateMethods.openSimpleMenuLevel3)
      .on('click', '#simplePanelLevel2 .SimpleFlyout__link--homeDecorFurniture', privateMethods.openSimpleMenuLevel3)
      .on('click', '#simplePanelLevel2 .SimpleFlyout__imageDescription', privateMethods.openSimpleMenuLevel3)
      .on('click', '#simplePanelLevel2 .SimpleFlyout__container .SimpleFlyout__list li.SimpleFlyout__listItem a.SimpleFlyout__link', privateMethods.logEventPathL3Link)
      .on('click', '#simplePanelLevel3 .SimpleFlyout__link', privateMethods.handleMenuLevel4)
      .on('click', '#simplePanelLevel3 .SimpleFlyout__link--homeDecorFurniture', privateMethods.handleMenuLevel4)
      .on('click', '#simplePanelMain', privateMethods.interactWithSimpleMenuLink)
      .on('click', '#simplePanelMain .SimpleFlyout__list li.SimpleFlyout__listItem a.SimpleFlyout__link', privateMethods.logEventPathTaskLink)
      .on('click', '.SimpleFlyout__listItem__grid--col', privateMethods.logFeaturedBrandsClick)
      .on('click', '.SimpleFlyout__headerImage', privateMethods.logHeaderImageClick)
      .on('click', '.SimpleFlyout__shop', privateMethods.logShopAllClick)
      .on('click', '.SimpleFlyout__listItem__recentlyViewedCategories--category', privateMethods.logRecentlyViewedCategory)
      .on('searchState', privateMethods.closeOnSearchOpen)
      .on('showSimpleFlyout', privateMethods.showSimpleFlyout);
  };

  /*
   * Public Methods
   */

  flyout.init = function init() {
    // Do it before ready - cause we rock
    privateMethods.initialize();

    // Start loading the data now
    headerData.init();

    // Render the L1 Categories
    if (hfConfig.flyoutVersion === '8') {
      privateMethods.renderL1Categories();
    }

    return this;
  };

  flyout.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  return flyout;
}));
