(function closure(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('hfConfig', ['global.namespace'],
      function load(THD) {
        return factory(root, THD);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.data = factory(root, THD);
    /*eslint-enable*/
  }
})(this, function factory() {
  var hfConfig = {};
  var privateMethods = {};

  var enableSignInBubbleDefault = false;
  var currentInstanceDefault = 's';
  var nonSecureHostNameDefault = 'www.homedepot.com';
  var secureHostNameDefault = 'www.homedepot.com';
  var storeSearchServiceHostNameDefault = 'www.homedepot.com';
  var flyoutVersionDefault = '6';
  var previewFlagDefault = false;
  var deepLinkUrlDefault = '';
  var undefinedVal = 'undefined';
  var isHomePageDefault = false;
  var isPLPPageDefault = false;

  privateMethods.isGlobalExists = function _isGlobalExists() {
    return typeof THD_GLOBAL !== undefinedVal;
  };
  privateMethods.globalPropExists = function _globalPropExists(prop) {
    return privateMethods.isGlobalExists() && typeof THD_GLOBAL[prop] !== undefinedVal && THD_GLOBAL.hasOwnProperty(prop);
  };
  privateMethods.enableSignInBubble = function _enableSignInBubble() {
    if (privateMethods.globalPropExists('enableSignInBubble')) {
      return THD_GLOBAL.enableSignInBubble;
    }
    return enableSignInBubbleDefault;
  };
  privateMethods.currentInstance = function _currentInstance() {
    if (privateMethods.globalPropExists('instance')) {
      return THD_GLOBAL.instance;
    }
    return currentInstanceDefault;
  };
  privateMethods.currentChannel = function _currentChannel() {
    if (privateMethods.globalPropExists('channel')) {
      return THD_GLOBAL.channel;
    }
    let currentChannelDefault = document.querySelectorAll('.footer__help #linkPcSite').length > 0 ? 'mobile' : 'web';
    
    return currentChannelDefault;
  };
  privateMethods.currentUrl = function _currentUrl() {
    return window.location.href;
  }
  privateMethods.nonSecureHostName = function _nonSecureHostName() {
    if (privateMethods.globalPropExists('nonSecureHostName')) {
      return THD_GLOBAL.nonSecureHostName;
    }
    return nonSecureHostNameDefault;
  };
  privateMethods.secureHostName = function _secureHostName() {
    if (privateMethods.globalPropExists('secureHostName')) {
      return THD_GLOBAL.secureHostName;
    }
    return secureHostNameDefault;
  };
  privateMethods.storeSearchServiceHostName = function _storeSearchServiceHostName() {
    if (privateMethods.globalPropExists('storeSearchServiceHostName')) {
      return THD_GLOBAL.storeSearchServiceHostName;
    }
    return storeSearchServiceHostNameDefault;
  };
  privateMethods.flyoutVersion = function _flyoutVersion() {
    if (privateMethods.globalPropExists('flyoutVersion')) {
      return THD_GLOBAL.flyoutVersion;
    }
    return flyoutVersionDefault;
  };
  privateMethods.previewFlag = function _previewFlag() {
    if (privateMethods.globalPropExists('isPv')) {
      return THD_GLOBAL.isPv;
    }
    return previewFlagDefault;
  };
  privateMethods.deepLinkUrl = function _deepLinkUrl() {
    if (privateMethods.globalPropExists('deepLinkUrl')) {
      return THD_GLOBAL.deepLinkUrl;
    }
    return deepLinkUrlDefault;
  };
  privateMethods.isHomePage = function _isHomePage() {
    if (privateMethods.globalPropExists('isHomePage')) {
      return THD_GLOBAL.isHomePage;
    }
    return isHomePageDefault;
  };
  privateMethods.isPLPPage = function _isPLPPage() {
    if (privateMethods.globalPropExists('isPLP')) {
      return THD_GLOBAL.isPLP;
    }
    return isPLPPageDefault;
  };
  privateMethods.isPIPPage = function _isPIPPage() {
    // 1. check for pip
    // 2. check for not pip package
    // 3. check for not pip compare page
    const { href } = window.location;
    return href.indexOf('/p/') !== -1 &&
      href.indexOf('/p/sets/') === -1 &&
      href.indexOf('/p/compare') === -1;
  };
  hfConfig.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  hfConfig.init = function () {
    return {
      'signInBubble': privateMethods.enableSignInBubble(),
      'currentInstance': privateMethods.currentInstance(),
      'currentChannel': privateMethods.currentChannel(),
      'currentUrl': privateMethods.currentUrl(),
      'nonSecureHostName': privateMethods.nonSecureHostName(),
      'secureHostName': privateMethods.secureHostName(),
      'storeSearchServiceHostName': privateMethods.storeSearchServiceHostName(),
      'flyoutVersion': privateMethods.flyoutVersion(),
      'previewFlag': privateMethods.previewFlag(),
      'deepLinkUrl': privateMethods.deepLinkUrl(),
      'isHomePage': privateMethods.isHomePage(),
      'isPLPPage': privateMethods.isPLPPage(),
      'isPIPPage': privateMethods.isPIPPage(),
      'isGlobal': privateMethods.isGlobalExists(),
      'getPrivate': hfConfig.getPrivateMethods()
    };
  };
  return hfConfig.init();
});