import $ from 'jquery';
import * as headerCart from 'header.cart';
import * as headerLogo from 'header.logo';
import * as headerDropDowns from 'header.dropdownmodule';
import * as headerMainFlyout from 'header.mainflyout';
import * as headerSimpleFlyout from 'header.simpleflyout';
import * as headerHomeDecorFurnitureFlyout from 'header.homedecorfurnitureflyout';
import * as headerSpecialsFlyout from 'header.specialsflyout';
import * as headerDIYFlyout from 'header.diyflyout';
import * as headerHsFlyout from 'header.hsflyout';
import * as headerMyaccount from 'header.myaccount';
import * as headerSearch from 'header.search';
import * as headerStorefinderCore from 'header.storefinder.core';
import * as headerStoreFinder from 'header.storefinder';
import * as headerTooltips from 'header.tooltips';
import * as headerShoppingLinks from 'header.shoppinglinks';
import * as headerTaskLinks from 'header.tasklinks';
import * as footerEmail from 'footer.email';
import * as footerPclink from 'footer.pcLink';
import footerOrderTracking from 'footer.orderTracking';
import * as gestureHandler from 'global.gestureHandler';
import * as headerAnalytics from 'header.analytics';
import * as headerStickyDesktop from 'header.stickyDesktop';
import deliveryZip from 'header.deliveryzip';

// Initialize all modules
const initializeModules = () => {
  const useJsonP = !!location.port || false;

  headerAnalytics.init();
  headerCart.init();
  headerMainFlyout.init();
  headerSimpleFlyout.init();
  headerHomeDecorFurnitureFlyout.init();
  headerSpecialsFlyout.init();
  headerDIYFlyout.init();
  headerHsFlyout.init();
  headerDropDowns.init();
  headerLogo.init();
  headerMyaccount.init(useJsonP);
  headerSearch.init();
  headerTooltips.init();
  headerStorefinderCore.init();
  headerStoreFinder.init();
  headerShoppingLinks.init();
  headerTaskLinks.init();
  footerEmail.init();
  footerPclink.init();
  footerOrderTracking.init();
  gestureHandler.init();
  headerStickyDesktop.init();
  deliveryZip.init();

  // Triggers HFApp is loaded and ready
  document.dispatchEvent(new Event('hfapp-ready', {
    bubbles: true,
    cancelable: false,
    detail: null
  }));
  $('body').trigger('hfapp-ready');
};

window.addEventListener('DOMContentLoaded', (event) => initializeModules());
