/* globals define */
/*
  Purpose:
    - Simple UI controller for the expansion and contraction of the search field

  Dependencies:
    - jQuery
    - THD Namespace
*/

(function closure(root, factory) {
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('header.search.core', ['jquery', 'barcode-scanner', 'hfConfig', 'global.namespace', 'typeAheadV2'], function load(jQuery, barcodeScanner, hfConfig, THD, typeAhead) {
      return factory(root, jQuery, barcodeScanner, hfConfig, THD, typeAhead);
    });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /* eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.search = factory(root, jQuery, THD.Global.TypeAhead);
    /* eslint-enable*/
  }
})(this, function factory(root, $, barcodeScanner, hfConfig, THD, typeAhead) {
  var search = {};
  var privateMethods = {};
  const isTaDesignEnabled = typeAhead.getPrivateMethods().isTaRedesignCookieAvailable();

  var config = {
    focusDelaySpeed: 300
  };

  privateMethods.redesignBarcode = function _redesignBarcode() {
    let updatedBarcodeHTML = `
    <img class="scanner__icon scanner__icongrey"
      src="https://assets.thdstatic.com/images/v1/barcode-grey.svg" alt="barcode scanner icon"
    />
    <div class="scanner__text-wrapper scanner__barcode">
      <span class="scanner__title">Scan a Barcode</span>
      <span class="scanner__description">Search a product by scanning it's barcode</span>
    </div>
    <img class="scanner__link"
      src="https://assets.thdstatic.com/images/v1/forward-grey.svg" alt="barcode scanner link"
    />
  `;
    let scannerContainer = document.querySelector('#js-barcode-scanner .scanner');
    if (scannerContainer) {
      scannerContainer.innerHTML = updatedBarcodeHTML;
    }
  };

  privateMethods.setExpanderState = function _setExpanderState(open) {
    var $header = $('.Header3');
    var $searchBox = $('.SearchBox');

    // collapse both, then collapse the 1st left
    $header.find('.Header3__collapser')
      .toggleClass('Header3__collapser--collapse', open)
      .eq(0)
        .toggleClass('Header3__collapser--collapse-left', open);

    $header.find('.Header3__expander')
      .toggleClass('Header3__expander--expand', open);

    $searchBox.find('.SearchBox__options')
      .toggleClass('SearchBox__options--open', open);

    //TODO: Change the hfConfig.currentChannel to mobile
    if (hfConfig.currentChannel === "mobile") {
      if(!isTaDesignEnabled) {
        $('#headerSearchButton').toggleClass('SearchBox__button--default', !open);
        $('.SearchBox__magGlass--default').toggleClass('u--hide', open);
        $('.SearchBox__magGlass--expanded').toggleClass('u--hide', !open);
      }
    }
  };

  // TODO dead code?
  /* istanbul ignore next  */
  privateMethods.setSearchBarState = function _setSearchBarState(open) {
    var $searchBar = $('.SearchBox__searchBar');

    $searchBar.toggleClass('SearchBox__searchBar--focus', open);

    $searchBar.find('.SearchBox__cover')
      .toggleClass('SearchBox__cover--closed', open);

    $searchBar.find('.SearchBox__input')
      .toggleClass('SearchBox__input--open', open);

    $searchBar.find('.SearchBox__button')
      .toggleClass('SearchBox__button--open', open);
  };

  privateMethods.setSearchState = function _setSearchState(open) {
    if (isTaDesignEnabled) privateMethods.redesignBarcode();
    privateMethods.setExpanderState(open);
    $(document).trigger('searchState', open);
  };

  privateMethods.isFocus = function _isFocus() {
    var $searchBox = $('.SearchBox');
    var $searchInput = $searchBox.find('.SearchBox__input');
    var $searchButton = $searchBox.find('.SearchBox__button');
    var focusOnSearch = ($searchInput.is(':focus') || $searchButton.is(':focus'));

    return focusOnSearch;
  };

  privateMethods.focusOnSearch = function _focusOnSearch() {
    if (!privateMethods.isFocus()) {
      $('.SearchBox .SearchBox__input').focus();
    }
  };

  search.openScanner = function _openScanner() {
    barcodeScanner.default.startScanning({ hostName: THD_GLOBAL.nonSecureHostName });
  };

  search.openSearch = function _openSearch(/* event */) {
    // event.preventDefault();

    privateMethods.setSearchState(true);

    // privateMethods.focusOnSearch();
    setTimeout(privateMethods.focusOnSearch, config.focusDelaySpeed);
  };


  search.closeSearch = function _closeSearch() {
    var closeCover = !($('.SearchBox__input').val() || privateMethods.isFocus());

    if (closeCover) {
      privateMethods.setSearchState(false);
    }
  };

  search.clearSearchField = function _clearSearchField(event) {
    event.preventDefault();
    $('.SearchBox__input').val('').trigger('blur');
    $(document).trigger('clearSearchField');
    $('#headerSearchGhost').val('');
    search.closeSearch();
  };

  // To validate if the user is already focused on searchbar
  search.checkSearchFocus = function _checkSearchFocus() {
	  var isSearchFocused = $('.SearchBox__input').is(':focus');

	  if (isSearchFocused) {
	      search.openSearch();
	  }
  };

  search.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  return search;
});
