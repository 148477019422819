/* globals define */
/*
 Purpose:
 - Simple UI controller to update the users name and show hide the login/register buttons
 Dependencies:
 - jQuery
 - Mustache
 - THD Namespace
 */

(function closure(root, factory) {
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('header.myaccount', ['jquery', 'mustache', 'header.analytics', 'cookie-utils', 'thd-customer','header.perks', 'hfConfig', 'plugin.tooltip', 'header.buyitagain'],
      function load(jQuery, Mustache, headerAnalytics, cookieUtils, thdCustomer, headerPerks,hfConfig, plugintooltip, BuyItAgain) {
        return factory(root, jQuery, Mustache, headerAnalytics, cookieUtils, thdCustomer, headerPerks,hfConfig, plugintooltip, BuyItAgain);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.maml = factory(root, jQuery, Mustache, HFApp.analytics, cookieUtils, thdCustomer, headerPerks,hfConfig, plugintooltip, BuyItAgain);
    /*eslint-enable*/
  }
})(this, function factory(root, $, Mustache, headerAnalytics, cookieUtils, thdCustomer,headerPerks, hfConfig, plugintooltip, BuyItAgain) {
  var maml = {};
  var privateMethods = {};
  var nonSecureHostName = hfConfig.nonSecureHostName;
  var secureHostName = hfConfig.secureHostName;
  BuyItAgain = BuyItAgain.default || BuyItAgain;
  thdCustomer = thdCustomer.default || thdCustomer;
  headerPerks = headerPerks.default || headerPerks;
  // determine if the cart is replete with items
  privateMethods.updateAccountIcon = function _updateAccountIcon(isLoggedIn, isPersisted) {
    var userSignedIn = isLoggedIn || isPersisted;
    var $accountIcon = $('.MyAccount__icon');

    $accountIcon.find('.HeaderIcon__primary')
      .toggleClass('HeaderIcon__primary--active', userSignedIn);

    $accountIcon.find('.HeaderIcon__secondary')
      .toggleClass('HeaderIcon__secondary--active', userSignedIn);
  };

  privateMethods.isPIPOrCollectionPage = function _isPIPOrCollectionPage() {
    var isPIP = (window.location.href.indexOf('/p/') !== -1);
    var isCollections = (window.location.href.indexOf('/collection/') !== -1);
    return (isPIP || isCollections);
  };
  privateMethods.clearPerksSessionStorage = function clearPerksSessionStorage() {
    return thdCustomer.clearPerksSession;
  };
  privateMethods.triggerSignInBubble = function _triggerSignInBubble() {
    $('#triggerSignIn').tooltip({
      type: 'click',
      noPad: true,
      closeOnClick: true,
      width: 'small',
      onOpen: function _onOpenTooltip($tooltip, complete) {
        $('#Header_signInBubble')
          .removeClass('hide')
          .appendTo($tooltip);

        complete();
        cookieUtils.fed_CreateCookie('signInBubble', true, null);
      }
    });
    $('#triggerSignIn').trigger('click');
    setTimeout(function () {
      if (!$('#Header_signInBubble').closest('[id*="tooltip"]').hasClass('ToolTip--closed')) {
        $('#triggerSignIn').trigger('click');
      }
    }, 7000);

  };

  privateMethods.initialize = function initialize(useJsonP) {
    var template = {
      loggedIn: '<div class="MyAccount__userInfo SimpleFlyout__link--bold">Hello, <span class="MyAccount__userInfo__name">{{usersName}}</span></div>',
      loggedInLite: '<span class="MyAccount__userInfo SimpleFlyout__link--bold">My Account</span>',
      loggedOut: '<div class="MyAccount__label SimpleFlyout__link--bold">My Account</div>'
    };

    /***
     * NPM module, thd-customer, reads THD_USER, THD_USER_SESSION, THD_CUSTOMER cookie from document.cookie
     * However, these cookies have secure flag which allows them to be only accessible in HTTPs
     */
    const { isLoggedIn, isPersisted, isB2CCustomer } = thdCustomer;

    // only grab usersName when it's in logged in or persisted log in state
    privateMethods.usersName = (isLoggedIn || isPersisted) ? cookieUtils.getTHDUserName() : '';
    // privateMethods.isBuyItAgain = ((isLoggedIn || isPersisted) && hfConfig.currentChannel !== 'mobile' && isB2CCustomer) || false;

    privateMethods.updateAccountIcon(isLoggedIn, isPersisted);

    privateMethods.determineAuthState(template);

    // Buy It Again only for logged in or persisted user;
    // if (privateMethods.isBuyItAgain) BuyItAgain.init(useJsonP);

    if (!cookieUtils.hasBrowserCookie("signInBubble") && !Boolean(privateMethods.usersName) && hfConfig.enableSignInBubble === true && hfConfig.currentChannel !== 'mobile') {
      if (privateMethods.isPIPOrCollectionPage()) {
        // Added timeout for PIP/Collection pages as they get reloaded when accessed directly
        setTimeout(function () {
          privateMethods.triggerSignInBubble();
        }, 3000);
      } else {
        privateMethods.triggerSignInBubble();
      }
    }
    if (thdCustomer.isB2XCustomer) {
      headerPerks.init();
    }
    if (thdCustomer.isB2XCustomer) {
      thdCustomer.getCustomerData('')
        .then((result) => {
          var res = JSON.parse(result);
          if (res.numberOfServiceAccounts > 1) {
            $('#myaccount-switch-accounts').removeClass('u--hide');
            $('#ma-switch-accounts-b2x').closest('.ma-drawer__link').removeClass('u--hide');
          }
        })
        .catch((err) => {
          // An error occurred
        });
      $("#b2c-links").remove();
      $('#headerMyAccount').tooltip({
        type: 'click',
        noPad: true,
        closeOnClick: true,
        onOpen: function _onOpenTooltip($tooltip, complete) {
          $('#B2CLinks').remove();
          $('#headerMyAccountDropdown')
            .removeClass('u--hide')
            .appendTo($tooltip);

          complete();

          $(document).trigger('openingHeaderToolTip', 'myaccount');
        }
      });
      $('#headerMyAccountList')
        .attr('href', 'https://' + hfConfig.nonSecureHostName + '/b2b/account/view/list/summary');
    } else {
      $("#b2x-links").remove();
      $('#headerMyAccount').tooltip({
        type: 'click',
        noPad: true,
        closeOnClick: true,
        onOpen: function _onOpenTooltip($tooltip, complete) {
          $('#B2XLinks').remove();
          $('#headerMyAccountDropdown')
            .removeClass('u--hide')
            .appendTo($tooltip);

          complete();

          // if (privateMethods.isBuyItAgain) BuyItAgain.setGridHeight($tooltip);

          $(document).trigger('openingHeaderToolTip', 'myaccount');
        }
      });
    }

    // attach event listener to signOut link
    $(document).on('click', 'button#signOut, button#ma-signout', privateMethods.signOut);

    /**
     * for DEMO Page
     */
    $(document).on('click', '.index-web__signin', () => {
      document.cookie = 'THD_USER="eyJjdXN0b21lclR5cGUiOiJCMkMiLCJzdm9jQ3VzdG9tZXJBY2NvdW50SWQiOiIwM0VEMjU2REZCMDAzNEEwMFMiLCJsb2dvbklkIjoidmVua2F0ZXNoLmNoaXRuaXNAZ21haWwuY29tIiwidXNlcklkIjoiMDUxM0UxNTJFMkRFMDMxRDBVIn0="; path=/;';
      document.cookie = 'THD_USER_SESSION=KPO6uUrjPLK8EdMUP9zsjXlEWWk.*AAJTSQACMDIAAlNLABw4WXQ3UEVZanpyZUkyN25HWGpoZnREbWNldTA9AAJTMQACMDE.*; path=/;';
      location.reload(true);
    });

    $(document).on('click', '.index-web__signout', () => {
      document.cookie = 'THD_USER=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'THD_USER_SESSION=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      location.reload(true);
    });

    return privateMethods;
  };

  privateMethods.determineAuthState = function determineAuthState(template) {
    let html;
    const { isLoggedIn, isPersisted } = thdCustomer;
    const { usersName } = privateMethods;
    const headerTitle = $('.headerMyAccountTitle');

    if (!isLoggedIn && !isPersisted) { // when there is no logged in user information
      // update dropdown links only in guest flow
      privateMethods.updateDropdownLinks();

      html = Mustache.render(template.loggedOut, privateMethods);
      headerTitle.html(html);

      if ($('#SPSOSignIn, #SPSORegister, #authDivider').hasClass('u--hide')) $('#SPSOSignIn, #SPSORegister, #authDivider').removeClass('u--hide');
      if (!$('#authSignOut, .MyAccount__message').hasClass('u--hide')) $('#authSignOut, .MyAccount__message').addClass('u--hide');
      $('#signOut.SimpleFlyout__link').parent().addClass('u--hide');
      $('#simpleFlyout .MyAccount__label--loggedout').toggleClass('u--hide', !Boolean(privateMethods.usersName));
      // update dropdown links only in guest flow
      return;
    }

    if (isPersisted || isLoggedIn) {
      // update the name in the button
      html = Mustache.render(template.loggedInLite, privateMethods);
      
      $("#ma-drawer__auth-buttons").addClass("u--hide");
      $("#ma-signout, #ma-signout-b2x").closest(".ma-drawer__link").removeClass("u--hide");
      if (usersName && usersName !== 'self') {
        html = Mustache.render(template.loggedIn, privateMethods);
        $('#ma-drawer__greeting').text(`Welcome Back, ${privateMethods.usersName}!`);
      } else {
        $('#ma-drawer__greeting').text("Welcome Back!");
      }
    }

    if (usersName === 'self') {
      html = Mustache.render(template.loggedInLite, privateMethods);
    }

    if (isLoggedIn && hfConfig.currentChannel === 'mobile') {
      $('.SimpleFlyout__myAccount').attr('href', `//${nonSecureHostName}/account/view/mobile/menu`);
    }

    headerTitle.html(html);

    // toggle login button state
    if (!$('#SPSOSignIn, #SPSORegister, #authDivider').hasClass('u--hide')) $('#SPSOSignIn, #SPSORegister, #authDivider').addClass('u--hide');
    if ($('#authSignOut, .MyAccount__message').hasClass('u--hide')) $('#authSignOut, .MyAccount__message').removeClass('u--hide');
    $('#simpleFlyout .MyAccount__label--loggedout').toggleClass('u--hide', !Boolean(privateMethods.usersName));
    return privateMethods;
  };

  privateMethods.updateDropdownLinks = function updateDropdownLinks() {
    try {
      const signInLink = $('#SPSOSignIn').find('a');
      const registerLink = $('#SPSORegister').find('a');
      const signInLinkDrawer = $('#ma-drawer-signin').find('a');
      const registerLinkDrawer = $('#ma-drawer-register').find('a');

      const { pathname } = document.location;
      const { hostname } = window.location;
      const referrer = hostname === 'www.homedepot.com' ? null : hostname;

      if (pathname) {
        signInLink && signInLink.attr('href', `${signInLink.attr('href')}?redirect=${pathname}&ref=${referrer}`);
        registerLink && registerLink.attr('href', `${registerLink.attr('href')}?redirect=${pathname}&ref=${referrer}`);
        signInLinkDrawer && signInLinkDrawer.attr('href', `${signInLinkDrawer.attr('href')}?redirect=${pathname}&ref=${referrer}`);
        registerLinkDrawer && registerLinkDrawer.attr('href', `${registerLinkDrawer.attr('href')}?redirect=${pathname}&ref=${referrer}`);
      }

      if (hfConfig.currentChannel === 'mobile') {
        $('#ma-myaccount-profile').attr('href', `//${nonSecureHostName}/auth/view/signin?redirect=/account/view/mobile/menu`);
      }
      $('#headerMyAccountProfile').attr('href', `//${nonSecureHostName}/auth/view/signin?redirect=/account/view/profile`);
      $('#headerInstantCheckoutSettings, #ma-instantCheckout').attr('href', `//${nonSecureHostName}/auth/view/signin?redirect=/account/view/instantcheckout`);
      $('#headerMyAccountSubscription, #ma-subscriptions').attr('href', `//${nonSecureHostName}/auth/view/signin?redirect=/account/view/ordersubscription`);
      $('#headerMyAccountMyList, #ma-lists').attr('href', `//${nonSecureHostName}/auth/view/signin?redirect=/list/view/summary`);
      $('#MobiletaskLinkFav').attr('href', `//${nonSecureHostName}/auth/view/signin?redirect=/list/view/summary`);
    } catch (e) {
      console.error(`Updating header myAccount dropdown link error, ${e}`);
    }
  };

  privateMethods.updateUserStatus = function updateUserStatus() {
    privateMethods.initialize();
    // refresh Cart
    $(document).trigger({ type: 'cartUpdated' });

    // refresh store
    $(document).trigger({ type: 'newStoreSet' });
  };

  privateMethods.logStoreFinderInteraction = function _logStoreFinderInteraction() {
    var text = $(this).closest('a').text().toLowerCase();
    return headerAnalytics.logEvent('header click', ['header', 'my account', text].join('>'));
  };

  privateMethods.logListHeaderClick = function _logListHeaderClick() {
    var text = $(this).closest('a').text().toLowerCase().replace(/[^0-9a-zA-Z]/g, '');
    return headerAnalytics.logEvent('header click', ['header', text].join('>'));
  };

  privateMethods.getSignOutRequest = function getSignOutRequest(url, channelId, Authorization) {
    $.support.cors = true;
    return $.ajax({
      url,
      type: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        channelId,
        Authorization,
      },
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
    });
  };

  privateMethods.signOut = function handleSignOut(event) {
    event.preventDefault();

    /**
     * CustomerAuth service ChannelId
     * 1: Desktop, 2: Mobile
     * @type {number}
     */
    const channelId = hfConfig.currentChannel === 'web' ? 1 : 2;

    const redirectToHomePage = () => window.location.assign(`https://${nonSecureHostName}`);
    const reloadPage = (xhr) => {
      if (xhr && xhr.status === 401) {
        window.location.reload();
      }
    };

    let url;
    let ajax;
    if (thdCustomer.isB2XCustomer) {
      url = `https://${secureHostName}/b2b/user/account/session/logout`;
      ajax = privateMethods.getSignOutRequest(url, channelId)
        .done(function () {
          privateMethods.clearPerksSessionStorage();
          redirectToHomePage();
        })
        .fail(reloadPage);

    } else {
      url = `https://${nonSecureHostName}/customer/auth/v1/logout`;
      const authorization = thdCustomer.token || cookieUtils.readBrowserCookie('THD_USER_SESSION');

      ajax = privateMethods.getSignOutRequest(url, channelId, authorization)
        .then(redirectToHomePage)
        .fail(reloadPage);
    }
    return ajax;
  };

  /*
   * Public Methods
   */
  maml.init = function init(useJsonP) {
    $(document)
      .on('click touchend', '#headerMyAccountDropdown .MyAccount__link', privateMethods.logStoreFinderInteraction)
      .on('click touchend', '#headerMyAccountList', privateMethods.logListHeaderClick)
      .on('updateUserStatus', privateMethods.updateUserStatus)
      .on('click', '#myaccount-switch-accounts', privateMethods.clearPerksSessionStorage)
      .ready(() => privateMethods.initialize(useJsonP));

    return this;
  };

  maml.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  return maml;
});
