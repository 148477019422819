/* globals define */
/*
  Purpose: Handles pushing updates to analytics
*/

(function closure(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('header.analytics', ['jquery', 'digitalData', 'Localizer'],
      function load($, digitalData, Localizer) {
        return factory(root, $, digitalData, Localizer);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.analytics = factory(root, jQuery, window.digitalData, Localizer);
    /*eslint-enable*/
  }
})(this, function factory(root, $, digitalData, Localizer) {
  /*
   * Private data
   */
  var module = {};
  var privateMethods = {};

  /*
   * Private Methods
   */

  privateMethods.createStashEventObject = function _createStashEventObject(type, detail) {
    return {
      eventMode: 'stash',
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: type.toLowerCase()
      },
      stash: {
        clickInfo: detail.toLowerCase()
      }
    };
  };

  privateMethods.pushEvent = function _pushEvent(eventObject) {
    if (!module.loaded) {
      throw new Error('header.analytics must be initialized before it can be used.');
    }

    // Push this thing in via the API when its loaded
    return module.loaded.then(function _pushEventWhenLoaded() {
      module.digitalDataApi.event.push(eventObject);
    });
  };

  privateMethods.pushUser = function _pushUser(userObject) {
    if (!module.loaded) {
      throw new Error('header.analytics must be initialized before it can be used.');
    } else if (module.digitalDataApi && module.digitalDataApi.user && module.digitalDataApi.user.localization) {
      return module.loaded.then(function _pushUserWhenLoaded() {
        var digitalDataZip = {'userZip' : userObject};
        var digitalDataZipObj = module.digitalDataApi.user.localization
        Object.assign(digitalDataZipObj, digitalDataZip);
      });
    }
  };

  /*
   * Public Methods
   */

  module.getPrivateMethods = function getPrivateMethods() {
    console.warn('getPrivateMethods should only be used for testing purposes');
    return privateMethods;
  };

  module.logEvent = function _logEvent(type, details) {
    var eventObject = privateMethods.createStashEventObject(type, details);

    return privateMethods.pushEvent(eventObject);
  };

  module.logEventObject = function _logEvent(eventObject) {
    return privateMethods.pushEvent(eventObject);
  };

  module.cartReminder = function _cartReminder() {

    const eventObject = {
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: { eventName: 'cart reminder' }
    };
    return privateMethods.pushEvent(eventObject);
  };

  module.logBuyItAgainClickToPIP = function _logBuyItAgainClickToPIP(payload) {
    const {
      pageType, displayPosition, strategy, featureVersion, interval
    } = payload;
    const eventObject = {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: { eventName: 'recommendation pip load' },
      eventMode: 'stash',
      stash: {
        recommendation: {
          anchorProductSku: 'n/a',
          recommendationType: 'reorder',
          pageType,
          displayPosition,
          strategy,
          recommendationVersion: 'model1',
          featureVersion,
        }
      }
    };

    if (interval) eventObject.stash.interval = interval;

    return privateMethods.pushEvent(eventObject);
  };

  module.logBuyItAgainAddToCart = function _logBuyItAgainAddToCart(payload) {
    const {
      pageType, displayPosition, interval, sku
    } = payload;
    const eventObject = {
      eventInfo: { eventName: 'cart addition info' },
      eventMode: 'stash',
      stash: {
        component: 'product pod',
        item: {
          displayPosition,
          productInfo: { sku },
          recommendation: {
            anchorProductSku: 'n/a',
            displayPosition,
            featureVersion: 'n/a',
            interval,
            pageType,
            recommendationType: 'reorder',
            recommendationVersion: 'model1',
            strategy: 'n/a'
          }
        },
        pageType,
        section: 'my account flyout',
        sharedSection: 'buy again',
        target: 'atc'
      }
    };

    return privateMethods.pushEvent(eventObject);
  };

  module.userAddedDeliveryZip = function _userAddedDeliveryZip(zipCode) {
    const userObject = zipCode
    const userZipInterval = setInterval(function(){
      return privateMethods.pushUser(userObject);
    }, 1000);
    if (module.digitalDataApi && module.digitalDataApi.user && 
      module.digitalDataApi.user.localization && module.digitalDataApi.user.localization.userZip){
      clearInterval(userZipInterval)
    }
    setTimeout(function(){
      clearInterval(userZipInterval);
   },6000);
  };

  module.init = function _init() {
    var loadedDefer;

    if (!module.loaded) {
      loadedDefer = $.Deferred();
      module.loaded = loadedDefer.promise();

      Localizer.localize().then(function _getApiWhenLoaded() {
        module.digitalDataApi = digitalData.get();
        loadedDefer.resolve();
      });
    }

    return module.loaded;
  };

  return module;
});
