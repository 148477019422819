import * as thdcustomer from 'thd-customer';

let instance;
class LoyaltyPerks {
  constructor() {
    this.init = this.init.bind(this);
  }
  init() {
    if (sessionStorage && !sessionStorage.getItem('isEnrolledInPerksProgram')) {
      thdcustomer.default.getLoyaltyPerks("")
        .then(result => {
          var res = JSON.parse(result);
          if (res.enrolledInPerksProgram) {
            $('#perks').removeClass('u--hide');
            $('#ma-perks-b2x').closest('.ma-drawer__link').removeClass('u--hide');
          }
        })
        .catch(err => {
          // An error occurred
        });
    } else {
      if (sessionStorage && sessionStorage.getItem('isEnrolledInPerksProgram') === 'true') {
        $('#perks').removeClass('u--hide');
        $('#ma-perks-b2x').closest('.ma-drawer__link').removeClass('u--hide');
      }
    }
    return null;
  }
}
if (!instance) {
  instance = new LoyaltyPerks();
}
export default instance;
