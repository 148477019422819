/* globals define */

/*
  Purpose:
    - to allow sticky header feature in homepage, plp, and pip for the Desktop

  Dependencies:
    - jquery, hfConfig
*/

(function closure(root, factory) {
    /* istanbul ignore else  */
    if (typeof define === 'function' && define.amd) {
      define('header.stickyDesktop', ['jquery', 'hfConfig'],
        function load(jQuery, hfConfig) {
          return factory(root, jQuery, hfConfig);
        });
    } else {
      // For non-AMD module, expose this to global (will also be useful for test cases)
      /*eslint-disable*/
      var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
      _hfapp.stickyDesktop = factory(root, jQuery, hfConfig);
      /*eslint-enable*/
    }
  })(this, function factory(root, $, hfConfig) {
    var module = {};
    var privateMethods = {};

    privateMethods.initialize = function initialize() {
      if(THD_GLOBAL.enableBassDesktopStickyHeader || THD_GLOBAL.enableHomeDesktopStickyHeader){
        var isHomeDecor = $(".homeDecorFurnitureSubnav")[0] ? true : false;
        var microsoftBrowser = navigator.userAgent.toLowerCase().indexOf('trident') > -1 || navigator.userAgent.toLowerCase().indexOf('edge') > -1 ? true : false;
        if (!isHomeDecor) {
          if (!microsoftBrowser) { //Other than MICROSOFT Browsers
            $('.Header3').addClass('Header3-sticky-desktop');
          } else {
            $('.Header3').addClass('Header3-sticky-ms-browser');
            $("#messageBar").css('margin-top', $('.Header3').height());
          }
        }
      }
    };
  
    /*
      * Public Methods
      */
    module.getPrivateMethods = function getPrivateMethods() {
      return privateMethods;
    };
  
    module.init = function init() {
      privateMethods.initialize();
      return this;
    };
    return module;
  });