/* globals define */
/*
  Purpose:
    - to handle hover and click of the header menus
  Dependencies
    - jquery
    - Mask
    - Data
*/
(function closure(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('header.dropdownmodule', ['jquery', 'header.mask', 'header.data', 'header.homedecorfurnitureflyout'],
      function load(jQuery, headerMask, headerData, homeDecorFurnitureFlyout) {
        return factory(root, jQuery, headerMask, headerData, homeDecorFurnitureFlyout);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.ddflyout = factory(root, jQuery, THD.HFApp.mask);
    /*eslint-enable*/
  }
})(this, function factory(root, $, headerMask, headerData, homeDecorFurniture) {
  var ddFlyout = {};
  var privateMethods = {};

  ddFlyout.templates = {};

  // Config the different flyouts here
  var menuConfig = headerData.getMenuConfig();

  privateMethods.setMenuState = function _setMenuState(menuID, value) {
    if (menuConfig[menuID].isActive !== 'undefined') {
      menuConfig[menuID].isActive = value;
    }
  };

  privateMethods.openMenu = function _openMenu(menuID) {
    privateMethods.setMenuState(menuID, true);

    var menuActiveClass = menuConfig[menuID].name + '__link--active';
    $('.' + menuActiveClass).removeClass(menuActiveClass);

    var menu = $('.ShoppingLinks__link[data-id="' + menuConfig[menuID].name + '"]');
    menu.addClass('ShoppingLinks__link--force-open');
    menu.addClass('js__flyout--open');

    if (menuID === 'homeDecorFurniture') {
      // Show Home Decor & Furniture flyout
      $('.homeDecorFurnitureFlyout__link--active').removeClass('homeDecorFurnitureFlyout__link--active');
      $('.homeDecorFurnitureFlyout__subnav-wrapper').addClass(menuConfig[menuID].menuClass + '--open');

      window.setTimeout(function _openFlyoutWithDelay() {
        // Only render the flyout it its data has been retrieved
        if (homeDecorFurniture.getHomeDecorFurnitureData()) {
          $('.homeDecorFurnitureFlyout__link#home-decor').addClass('homeDecorFurnitureFlyout__link--active');
          // Automatically render Home Decor flyout
          $('.homeDecorFurnitureFlyout__menu').addClass('homeDecorFurnitureFlyout__menu--open');
          homeDecorFurniture.getPrivateMethods().renderMenu('Home Decor');
        }
      }, 500);
    } else {
      $('.' + menuConfig[menuID].menuClass).addClass(menuConfig[menuID].menuClass + '--open');

      // Close the Home Decor & Furniture flyout if it opened after user switched to a different menu
      window.setTimeout(function _closeHomeDecorFurnitureFlyoutIfOpen() {
        $('.homeDecorFurnitureSubnav__subnav-wrapper').removeClass('homeDecorFurnitureSubnav--open');
        $('.homeDecorFurnitureFlyout__menu-wrapper').removeClass('homeDecorFurnitureFlyout--open');
      }, 500);
    }
  };

  privateMethods.closeMenu = function _closeMenu(menuID) {
    privateMethods.setMenuState(menuID, false);

    $('.ShoppingLinks__link[data-id="' + menuID + '"]')
      .removeClass('ShoppingLinks__link--force-open')
      .off('.flyout');

    $('.MainFlyout')
      .removeClass('MainFlyout--open')
      .removeClass('MainFlyout--panels-3')
      .removeClass('MainFlyout--panels-4')
      .find('.MainFlyout__link').removeClass('MainFlyout__link--active');

    $('.' + menuConfig[menuID].menuClass).removeClass(menuConfig[menuID].menuClass + '--open');

    if (menuID === 'homeDecorFurniture') {
      $('.homeDecorFurnitureSubnav__subnav-wrapper').removeClass('homeDecorFurnitureSubnav--open');
      $('.homeDecorFurnitureFlyout__link--active').removeClass('homeDecorFurnitureFlyout__link--active');

      homeDecorFurniture.highlightSubnavAisle('.homeDecorFurnitureFlyout__subnav', homeDecorFurniture.getBreadcrumbAisleElement());
    }

    // Close the Home Decor & Furniture menu if it opened after user closed the Home Decor & Furniture menu
    window.setTimeout(function _closeHomeDecorFurnitureFlyoutIfOpen() {
      $('.homeDecorFurnitureFlyout__menu-wrapper').removeClass('homeDecorFurnitureFlyout--open');
    }, 500);

    headerMask.destroy();
  };

  privateMethods.closeAllMenus = function _closeAllMenus() {
    Object.keys(menuConfig).forEach(function _closeEachMenu(key) {
      privateMethods.closeMenu(key);
    });
    privateMethods.removeHoverIntent('.Header3');
  };

  privateMethods.toggleMenu = function _toggleMenu(event) {
    var $this = $(this);
    var menuID = $this.data('id');

    if (!privateMethods.isSurfacePro() && menuID) {
      $(document).trigger('closeHeaderTooltips');
      if (headerData.isDropDown() || menuID === 'departmentsFlyout') {
        event.preventDefault();
        event.stopPropagation();
      }
      if (menuConfig[menuID].isActive && event.type !== 'hoverintent') {
        privateMethods.closeMenu(menuID);
      } else {
        privateMethods.closeAllMenus();
        privateMethods.setMenuState(menuID, true);
        if (headerData.isDropDown() || menuID === 'departmentsFlyout') {
          headerMask.show();
          privateMethods.openMenu(menuID);
        }
      }
    } else {
      privateMethods.closeAllMenus();
    }
    privateMethods.closeTypeAhead();
  };

  privateMethods.isSurfacePro = function isSurfacePro() {
    if (!(navigator.userAgent).match(/Edge/g)) {
      return (navigator.userAgent.match(/Edge/g) !== null);
    }
    return false;
  };

  privateMethods.removeHoverIntent = function _removeHoverIntent($element) {
    $($element).off('.hoverIntent');
    return $element;
  };

  privateMethods.triggerHoverintent = function _triggerHoverIntent() {
    $(this).trigger({
      type: 'hoverintent',
      target: this
    });
  };

  privateMethods.breakpointChanged = function _breakpointChanged(event, eventInfo) {
    var newInList = ['lg', 'md', 'sm'].indexOf(eventInfo.new);
    var oldInList = ['default', 'xs'].indexOf(eventInfo.old);

    if ((newInList > -1) && (oldInList > -1)) {
      // If we're in a larger break point
      $(document).trigger('showSimpleFlyout', false);
    } else if ((newInList === -1) && (oldInList === -1)) {
      // If we're in a smaller break point
      privateMethods.closeAllMenus();
    }
  };

  privateMethods.closeOnSearchOpen = function _closeOnSearchOpen(event, isOpen) {
    if (isOpen) {
      privateMethods.closeAllMenus();
    }
  };

  privateMethods.closeTypeAhead = function closeTypeAhead() {
    var headerSearch = $('#headerSearch');
    var isSearchBarFocused = (headerSearch.is(':focus'));
    if (isSearchBarFocused) {
      headerSearch.blur();
      $('#typeAheadFlyOut').removeClass('typeahead__show');
    }
  };

  privateMethods.initialize = function initialize() {
    $(document)
      .on('touchend hoverintent', '.Header3 .ShoppingLinks__link', privateMethods.toggleMenu)
      .on('click touchend hoverintent', '.Mask', privateMethods.closeAllMenus)
      .on('click touchend', '.Header3', privateMethods.closeAllMenus)
      .on('closeAllMenus', privateMethods.closeAllMenus)
      .on('breakpointChanged', privateMethods.breakpointChanged)
      .on('searchState', privateMethods.closeOnSearchOpen)
      .hoverIntent({
        selector: '.Header3 .ShoppingLinks__link',
        over: privateMethods.triggerHoverintent,
        out: $.noop
      })
      .hoverIntent({
        selector: '.Header3 .HeaderLogo',
        over: privateMethods.triggerHoverintent,
        out: $.noop
      });
  };

  /*
   * Public Methods
   */

  ddFlyout.templates.diyandhs =
    '<section class="grid diyFlyout__level1">' +
    '    <div class="col__12-12 diyFlyout__wrapper">' +
    '        <div class="grid">' +
    '            {{#links}} ' +
    '                <div class="col__3-12">' +
    '                    <div class="content">' +
    /* based on CTA, image is rendered as clickable or only image */
    '                        <div class="content_image">' +
    '                            {{#link}} ' +
    '                                <a href="{{link}}" alt="{{{title}}}">' +
    '                                    <img src="{{image}}" class="diyFlyout__image stretchy">' +
    '                                </a>' +
    '                            {{/link}} ' +
    '                            {{^link}} ' +
    '                                <img src="{{image}}" class="diyFlyout__image stretchy">' +
    '                            {{/link}} ' +
    '                        </div>' +
    /* based on CTA, text is rendered as clickable or only text */
    '                        {{#link}} ' +
    '                            <a href="{{link}}" class="cta--inline diyFlyout__level2link">' +
    '                                <span class="cta__text">' +
    '                                    {{{title}}} ' +
    '                                </span>' +
    '                                <span class="cta__arrow"></span>' +
    '                            </a>' +
    '                        {{/link}} ' +
    '                        {{^link}} ' +
    '                            <h4 class="cta__text diyFlyout__level2text">' +
    '                                {{{title}}} ' +
    '                            </h4>' +
    '                        {{/link}} ' +
    '                        <ul class="list list--type-plain diyFlyout__list">' +
    '                            {{#sublinks}} ' +
    '                                <li class="list__item">' +
    '                                    <a href="{{link}}" class="list__link diyFlyout__level3link">{{{title}}}</a>' +
    '                                </li>' +
    '                            {{/sublinks}} ' +
    '                        </ul>' +
    '                    </div>' +
    '                </div>' +
    '            {{/links}} ' +
    '        </div>' +
    '    </div>' +
    '</section>;';

  ddFlyout.init = function init() {
    privateMethods.initialize();
    return this;
  };

  ddFlyout.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  return ddFlyout;
});
