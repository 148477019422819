/*
	Dependencies
		- jquery
		- THD Namespace
*/
(function closure(root, factory) {
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('footer.email', ['jquery', 'global.namespace', 'thd-overlay', 'Localizer', 'hfConfig'],
      function load(jQuery, THD, thdOverlay, Localizer, hfConfig) {
        return factory(root, jQuery, THD, thdOverlay, Localizer, hfConfig);
      });
  } else {
    /* eslint-disable*/
    // For non-AMD module, expose this to global (will also be useful for test cases)
    let _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.email = factory(root, jQuery, THD, THD.Global.overlayConfigs, Localizer, hfConfig);
    /* eslint-enable*/
  }
})(this, function factory(root, $, THD, thdOverlay, Localizer, hfConfig) {
  let email = {};
  let privateMethods = {};
  // Private Methods
  // replace this common validation function with one in thdUtils at some point
  privateMethods.check = function check(emailAddress) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailAddress);
  };

  // show/hde the inline error
  privateMethods.toggleError = function toggleError(show) {
    $('#newsletterEmailError')
      .toggleClass('u--show', show)
      .siblings('.footerEmailSignup__controlGroup').toggleClass('footerEmailSignup__controlGroup--error', show);
    return privateMethods;
  };

  privateMethods.getZipCode = function getZipCode() {
    const localizedStore = Localizer.getLocalizedStore();
    if (localizedStore === undefined) { return `00000` }
    return localizedStore.zipcode;
  },

  // submits the email to through ajax request
  privateMethods.sendEmail = function sendEmail(emailAddress) {
    let theHref = 'https://' + hfConfig.secureHostName + '/customer/account/v1/guest/preferences?email=' + emailAddress + '&zipCode=' + privateMethods.getZipCode();
    $.ajax({
      url: theHref,
      type: 'PUT',
      data: '{"preferences": {"preference": [{"isSubscribed": true,"code": "PROMOTIONS"}]}}',
      headers: { "content-type": "application/json", "channelId": "1" },
      success: function (response) {
        const successMsg = 'Thank you for signing up.' + '</br>' + 'Your first email should arrive shortly.';
        if (response.preferences.status === 'SUCCESS') {
          privateMethods.populateContent(successMsg);
        }
      },
      error: function (response) {
        let errorMsg = 'We apologize, due to system error, we were ' + '</br>' + 'unable to add your email.' + '</br>' + 'Please try again later.';
        if (response.responseJSON.preferences.errors.length !== '0') {
          privateMethods.populateContent(errorMsg);
        }
      }
    })

    return privateMethods;
  };

  privateMethods.populateContent = function populateContent(data) {
    let emailOverlayContent = document.createElement('div');
    emailOverlayContent.innerHTML = '<div class="footerEmailSignup__signUpMessage--box">' +
      '<div class = "footerEmailSignup__signUpMessage--text">' + data + '</div></div>';
    let overlayObj = new thdOverlay({
      speed: '500ms',
      channel: 'desktop',
      useContent: true,
      header: false,
      closeButton: true,
      title: '',
      htmlNode: emailOverlayContent
    });
    overlayObj.open();
  }

  // controller
  privateMethods.validate = function validate(e) {
    let $this = $(this);
    let emailAddress = $this.find('input[type=email]').val();
    let isValid = privateMethods.check(emailAddress);

    e.preventDefault();

    if (isValid) {
      privateMethods
        .toggleError(false)
        .sendEmail(emailAddress, $, root);

      $this.find('input[type=email]').val('');
    } else {
      privateMethods.toggleError(true);
    }
  };


  email.init = function init() {
    $(document).on('submit', '#footerEmailSignup', privateMethods.validate);
    return this;
  };

  email.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  return email;
});
