/* Mobile smartBanner define */
(function closure(root, factory) {
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('footer.pcLink', ['jquery', 'cookie-utils', 'hfConfig'],
      function load(jQuery, cookieUtils, hfConfig) {
        return factory(root, jQuery, cookieUtils, hfConfig);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    const _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.pcLink = factory(root, jQuery, cookieUtils, hfConfig);
    /*eslint-enable*/
  }
})(this, function factory(root, $, cookieUtils, hfConfig) {
  let module = {};

  const privateMethods = {};

  privateMethods.clickHandler = function (e) {
    e.preventDefault();
    privateMethods.cookieEdits();

    let pcSiteUrl = '';
    const absoluteUrlPattern = /^https?:\/\//i;

    let thisHREF = $(this).data('href');

    if (typeof (hfConfig.deepLinkUrl) === 'undefined') {
      pcSiteUrl = thisHREF;
    } else if (!absoluteUrlPattern.test(hfConfig.deepLinkUrl)) {
      pcSiteUrl = thisHREF + hfConfig.deepLinkUrl;
    } else {
      pcSiteUrl = hfConfig.deepLinkUrl;
    }

    privateMethods.linkHandler(pcSiteUrl);
  };

  privateMethods.cookieEdits = function () {
    if (hfConfig.currentChannel === 'web' && cookieUtils.hasBrowserCookie("THD_USR_AGENT_OVERRIDE")) {
      cookieUtils.deleteBrowserCookie('THD_USR_AGENT_OVERRIDE', THD_GLOBAL.cookieDomain);
      return;
    } else if (hfConfig.currentChannel === 'mobile') {
      cookieUtils.setCookieExpireInSeconds('THD_USR_AGENT_OVERRIDE', 'FULL', 18000, THD_GLOBAL.cookieDomain);
      cookieUtils.deleteBrowserCookie('THD_ONLINE_CHANNEL', THD_GLOBAL.cookieDomain);
      cookieUtils.deleteBrowserCookie('MOBILE_WEB', THD_GLOBAL.cookieDomain);
      return;
    }
  };

  /* istanbul ignore next  */
  privateMethods.linkHandler = function (pcSiteUrl) {
    // window.location.href = url; // Below looks cleaner; Works same way.
    window.location.assign(pcSiteUrl);
  }

  module.init = function _init() {
    $(document).on('click', '#linkPcSite', privateMethods.clickHandler);
  };

  module.getPrivateMethods = function () {
    return privateMethods;
  };

  return module;
});

