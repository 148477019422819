/* globals define */
/*
  Purpose: Handles data requests to load flyout
*/

(function closure(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('header.data', ['jquery', 'window', 'hfConfig'],
      function load(jQuery, window, hfConfig) {
        return factory(root, jQuery, window, hfConfig);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /*eslint-disable*/
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.data = factory(root, jQuery, window, THD.Utility.Config);
    /*eslint-enable*/
  }
})(this, function factory(root, $, window, hfConfig) {
  /*
   * Private data
   */
  var module = {};
  var flyoutVersion = hfConfig.flyoutVersion;
  var privateMethods = {};
  var privateData = {
    version: flyoutVersion
  };
  var pixelSwitchInterval = 1024;
  /*
   * Private Methods
   */

  // sets the protocol
  privateMethods.determineHostName = function determineHostName() {
    return (window.location.hostname === hfConfig.secureHostName) ? hfConfig.secureHostName : hfConfig.nonSecureHostName;
  };

  // determines if we are in the staging LLC
  privateMethods.getStagingFlag = function getStagingFlag() {
    return hfConfig.currentInstance === 's' ? '&instance=s' : '';
  };

  privateMethods.getFlyoutDataUrl = function _getFlyoutDataUrl() {
    var previewFlag = hfConfig.previewFlag ? 'PV-' : '';
    var hostName = privateMethods.determineHostName();

    if (THD_GLOBAL.HFAPP_useCdnFlyout) {
      var flyOutUrl = '//' + hostName + '/hdus/en_US/DTCCOMNEW/fetch/headerFooterFlyout-{PREVIEW}{VERSION}.json';

      flyOutUrl = flyOutUrl.replace('{PREVIEW}', previewFlag);
      return flyOutUrl.replace('{VERSION}', privateData.version);
    } else {
      var stagingFlag = privateMethods.getStagingFlag();
      var versionFlag = (privateData.version ? '&version=' + previewFlag + privateData.version : '');
      return '//' + hostName + '/hf/rs/staticFly/headerFooterFlyout?env=&pro=false&secureClient=false' + stagingFlag + versionFlag;
    }
  };

  privateMethods.createLevel3Key = function _createLevel3Key(level1Name, level2Name) {
    return [level1Name, level2Name].join(':');
  };

  privateMethods.cacheLevel3Data = function _cacheLevel3Data(level1Name, level2Name, level3Data) {
    var cacheKey = privateMethods.createLevel3Key(level1Name, level2Name);

    privateData.level3[cacheKey] = level3Data;
  };

  privateMethods.cacheLevel1Data = function _cacheLevel1Data(completeData) {
    privateData.level1['Categories'] = [];
    $.each(completeData, function (i, item) {
      var disableL1 = (typeof item.hide !== 'undefined') ? item.hide : false;
      // Push the token into the current array
      privateData.level1['Categories'].push({
        name: item.title,
        link: item.link,
        hideL1: disableL1,
        activeHolidayClass: item.activeHolidayClass,
        feature: item.feature
      });
    });
  };

  // Most of this work could be a map, but support isn't there yet
  privateMethods.cacheLevel2DataV6 = function _cacheLevel2DataV6(categoryName, completeData) {
    var currentCategory;

    privateData.level2[categoryName] = [];

    for (var x = 0; x < completeData.length; x++) {
      currentCategory = completeData[x];

      // Push the token into the current array
      privateData.level2[categoryName].push({
        name: currentCategory.name,
        url: currentCategory.url
      });

      // Cache the next level
      privateMethods.cacheLevel3Data(categoryName, currentCategory.name, currentCategory.l3);
    }
  };
  privateMethods.cacheLevel2DataV8 = function _cacheLevel2DataV8(categoryName, completeData) {
    var currentCategory;

    privateData.level2[completeData.title] = [];
    // completeData = (typeof completeData.l2 !== 'undefined') ? completeData.L2 : completeData;
    for (var x = 0; x < completeData.l2.length; x++) {
      currentCategory = completeData.l2[x];

      // Push the token into the current array
      privateData.level2[completeData.title].push({
        name: currentCategory.name,
        url: currentCategory.url
      });

      // Cache the next level
      privateMethods.cacheLevel3Data(completeData.title, currentCategory.name, currentCategory.l3);
    }
  };

  privateMethods.clearCachedData = function _clearCachedData() {
    privateData.level1 = {};
    privateData.level2 = {};
    privateData.level3 = {};
  };

  privateMethods.cacheData = function _cacheData(data) {
    var responseData = (flyoutVersion === '6') ? data : data.header.primaryNavigation;
    // Clear cache
    privateMethods.clearCachedData();
    // check the version to loop L1
    if (flyoutVersion === '8') {
      privateMethods.cacheLevel1Data(responseData);
      $.each(responseData, privateMethods.cacheLevel2DataV8);
    } else {
      // Loop over the data
      $.each(responseData, privateMethods.cacheLevel2DataV6);
    }

    return privateData;
  };

  privateMethods.requestData = function _requestData(ajaxUrl) {
    if (THD_GLOBAL.HFAPP_useCdnFlyout) {
      return $.get({
        url: ajaxUrl,
        cache: true,
        timeout: 3000
      });
    } else {
      return $.ajax({
        dataType: 'jsonp',
        url: ajaxUrl,
        jsonpCallback: 'staticFlyCallback',
        cache: true,
        timeout: 3000
      });
    }
  };

  /** the method was only for testing locally
   privateMethods.requestDataFly = function _requestDataFly() {
    return $.getJSON("headerFooterData.json", function(json){
    });
  }; **/

  // Async fetch of the L1-L2-L3 JSON from the header app
  privateMethods.loadFlyoutData = function loadFlyoutData() {
    var loadDataDefer = $.Deferred();
    var dataUrl = privateMethods.getFlyoutDataUrl();
    var ajaxRequest = privateMethods.requestData(dataUrl);

    if (THD_GLOBAL.HFAPP_useCdnFlyout) {
      ajaxRequest = ajaxRequest.then(function (response) {
        var replacedJson = JSON.stringify(response)
          .replace(/NON_SECURE/g, hfConfig.nonSecureHostName)
          .replace(/SECURE_SUPPORTED/g, '//' + hfConfig.nonSecureHostName)
          .replace(/SECURE/g, 'https://' + hfConfig.secureHostName);

        return JSON.parse(replacedJson);
      });
    }

    ajaxRequest
      .then(privateMethods.cacheData)
      .then(loadDataDefer.resolve);

    ajaxRequest.fail(function _ajaxRequestFailure() {
      loadDataDefer.reject('Failed to load flyout data.');
    });

    return loadDataDefer.promise();
  };

  privateMethods.initialize = function initialize() {
    if (!privateData.dataLoaded) {
      // initialize cache
      privateMethods.clearCachedData();

      // load the data
      privateData.dataLoaded = privateMethods.loadFlyoutData();
    }

    return privateData.dataLoaded;
  };

  privateMethods.getBreakpointFromWidth = function _getBreakpointFromWidth(currentWidth) {
    var result = 'default';

    if (currentWidth >= 1440) {
      result = 'lg';
    } else if (currentWidth >= 1280) {
      result = 'md';
    } else if (currentWidth >= 1024) {
      result = 'sm';
    } else if (currentWidth >= 640) {
      result = 'xs';
    }

    return result;
  };

  privateMethods.checkForBreakpointChange = function _checkForBreakpointChange() {
    var windowWidth = $(window).width();
    var currentBreakpoint = privateMethods.getBreakpointFromWidth(windowWidth);

    if (privateData.breakpoint !== currentBreakpoint) {
      $(document).trigger('breakpointChanged', [{
        old: privateData.breakpoint,
        new: currentBreakpoint
      }]);
    }

    privateData.breakpoint = currentBreakpoint;
  };

  privateMethods.getEtchJSON = function _getEtchJSON(jsonPath) {
    var loadDataDefer = $.Deferred();

    var hostName = privateMethods.determineHostName();
    var ajaxUrl = '//' + hostName + jsonPath;

    $.ajax({
      dataType: 'json',
      url: ajaxUrl,
      cache: true,
      timeout: 5000,
      success: function _success(data) {
        loadDataDefer.resolve(data);
      },
      error: function _error(jqXHR, textStatus, errorThrown) {
        loadDataDefer.reject(errorThrown);
      },
      fail: function _fail() {
        loadDataDefer.reject('Failed to load flyout data.');
      }
    });

    return loadDataDefer.promise();
  };

  /*
   * Public Methods
   */
  module.init = function init() {
    /* Events
    ------------------------------------------------ */
    module.loaded = privateMethods.initialize();

    $(window).on('resize', privateMethods.checkForBreakpointChange);

    return module.loaded;
  };

  module.checkEventBus = function checkEventBus() {
    return (typeof window !== 'undefined' && (window.THD_EVENT_BUS || window.LIFE_CYCLE_EVENT_BUS));
  };

  module.isSurfacePro = function isSurfacePro() {
    if (!(navigator.userAgent).match(/Edge/g)) {
      return (navigator.userAgent.match(/Edge/g) !== null);
    }
    return false;
  };

  module.getMenuConfig = function getMenuConfig() {
    // config the different flyouts here
    return {
      'departmentsFlyout': {
        'name': 'departmentsFlyout',
        'menuClass': 'MainFlyout',
        'isActive': false
      },
      'homeDecorFurniture': {
        'name': 'homeDecorFurniture',
        'menuClass': 'homeDecorFurnitureFlyout',
        'isActive': false
      },
      'diyProjectsIdeas': {
        'name': 'diyProjectsIdeas',
        'menuClass': 'diyFlyout',
        'isActive': false
      },
      'homeServices': {
        'name': 'homeServices',
        'menuClass': 'hsFlyout',
        'isActive': false
      },
      'specialOffers': {
        'name': 'specialOffers',
        'menuClass': 'specialOffersFlyout',
        'isActive': false
      }
    };
  };

  module.isDropDown = function isDropDown() {
    var hostName = window.location.hostname;
    var host = privateMethods.determineHostName();
    var localHost = 'localhost.homedepot.com'; // I'm checking against localhost since we can use cors plugin for dev
    return hostName === localHost || hostName === host;
  };

  module.getEtchJSON = function getEtchJSON(url) {
    var loadDataDefer = $.Deferred();
    var getEtchDataPromise = privateMethods.getEtchJSON(url);

    getEtchDataPromise.then(function (data) {
      loadDataDefer.resolve(data);
    });

    return loadDataDefer.promise();
  };

  module.getHostName = function getHostName() {
    return privateMethods.determineHostName();
  };

  module.isNotThirdParty = module.isDropDown;

  module.isSmallWidth = function isSmallWidth() {
    return $(window).width() < pixelSwitchInterval;
  };

  module.isCartPage = function isCartPage(url) {
    return url.indexOf('/mycart/home') !== -1;
  };
  module.isThankYouPage = function isThankYouPage(url) {
    return url.indexOf('/mycheckout/thankyou#') !== -1;
  };

  module.getPrivateMethods = function getPrivateMethods() {
    console.warn('getPrivateMethods should only be used for testing purposes');
    return privateMethods;
  };

  module.getPrivateData = function getPrivateData() {
    console.warn('getPrivateData should only be used for testing purposes');
    return privateData;
  };

  // if you are a touch device
  module.isTouchDevice = function isTouchDevice() {
    return !(!('ontouchstart' in window) && !window.navigator.msMaxTouchPoints && !window.navigator.userAgent.toLowerCase().match(/windows phone os 7/i));
  };

  module.getLevel3Key = function _getLevel3Key() {
    var args = Array.prototype.slice.call(arguments);
    return privateMethods.createLevel3Key.apply(null, args);
  };

  module.getLevel1Data = function _getLevel1Data() {
    var dataDefer = $.Deferred();

    // If Load started
    if (privateData.dataLoaded) {
      // Wait for load to complete
      privateData.dataLoaded.then(function _level1DataLoaded() {
        // check for the value
        if (privateData.level1['Categories'] && privateData.level1['Categories'].length) {
          // return it
          dataDefer.resolve(privateData.level1['Categories']);
          // This looks like a failure to load data
        } else {
          dataDefer.reject('Could not find level 1 value in Categories');
        }
      });

      // Not loaded yet
    } else {
      dataDefer.reject('Data is not loaded');
    }

    return dataDefer.promise();
  };

  module.getLevel2Data = function _getLevel2Data(level1Name) {
    var dataDefer = $.Deferred();

    // If Load started
    if (privateData.dataLoaded) {
      // Wait for load to complete
      privateData.dataLoaded.then(function _level2DataLoaded() {
        // check for the value
        if (privateData.level2[level1Name] && privateData.level2[level1Name].length) {
          // return it
          dataDefer.resolve(privateData.level2[level1Name]);
          // This looks like a failure to load data
        } else {
          dataDefer.reject('Could not find level 2 value for "' + level1Name + '"');
        }
      });

      // Not loaded yet
    } else {
      dataDefer.reject('Data is not loaded');
    }

    return dataDefer.promise();
  };

  module.getLevel3Data = function _getLevel3Data(level1Name, level2Name) {
    var dataDefer = $.Deferred();
    var cacheKey = privateMethods.createLevel3Key(level1Name, level2Name);

    // If Load started
    if (privateData.dataLoaded) {
      // Wait for load to complete
      privateData.dataLoaded.then(function _level3DataLoaded() {
        // check for the value
        if (privateData.level3[cacheKey] && privateData.level3[cacheKey].length) {
          // return it
          dataDefer.resolve(privateData.level3[cacheKey]);
          // This looks like a failure to load data
        } else {
          dataDefer.reject('Could not find level 3 value for "' + level1Name + '" and "' + level2Name + '"');
        }
      });

      // Not loaded yet
    } else {
      dataDefer.reject('Data is not loaded');
    }

    return dataDefer.promise();
  };

  return module;
});
