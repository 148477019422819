/* global define */
/*
  Purpose:
    - Updates the cart button and determines if the cart dropdown should be created.
  Dependencies:
    - THD Namespace
    - jquery
    - mustache

*/

(function closure(root, factory) {
  var _hfapp;
  /* istanbul ignore else  */
  if (typeof define === 'function' && define.amd) {
    define('header.cart', [ 'hfConfig', 'jquery', 'mustache', 'global.namespace', 'cookie-utils', 'header.analytics'],
      function loader(hfConfig, jQuery, Mustache, THD, cookieUtils, headerAnalytics) {
        return factory(hfConfig, root, jQuery, Mustache, THD, cookieUtils, headerAnalytics);
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /* eslint-disable*/
    _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.cart = factory(root, root.jQuery, root.Mustache, THD, THD.Utility.Config, cookieUtils, HFApp.analytics);
    /* eslint-enable*/
  }
})(this, function factory(hfConfig, root, $, Mustache, THD, cookieUtils, headerAnalytics) {
  var cart = {};
  // Private methods
  var privateMethods = {};
  var templates = {};
  templates.dropdown = '' +
    '<div class="MyCart__dropdown">' +
      '<div class="grid">' +
        '<div class="col__12-12">' +
          '<ul class="MyCart__list">' +
            '{{#blinds}}' +
              '<li class="MyCart__item">' +
                '<a id="blindsDropdownLink" href="https://{{determineBlindsHostName}}/Ordering/Cart" class="MyCart__link">Blinds Samples' +
                  '<span class="MyCart__count">{{blinds}} item{{blindsPlural}}</span>' +
                '</a>' +
              '</li>' +
            '{{/blinds}}' +
            '{{#mini}}' +
            '<li class="MyCart__item">' +
              '<a id="cartDropdownLink" href="http://{{hostNameNonSecure}}/{{cartURLWithThrottle}}" class="MyCart__link">Cart' +
                '<span class="MyCart__count">{{mini}} item{{miniPlural}}</span>' +
              '</a>' +
            '</li>' +
            '{{/mini}}' +
          '</ul>' +
        '</div>' +
      '</div>' +
    '</div>' +
  '';

  // if there are only blinds products in the cart then we need to update the cart link
  privateMethods.updateCartUrl = function _updateCartUrl($cartBtn) {
    var blinds = privateMethods.blinds;
    var mini = privateMethods.mini;

    var cartLink = (blinds > 0 && mini === 0)
      ? 'https://' + privateMethods.determineBlindsHostName() + '/Ordering/Cart'
      : '//' + privateMethods.hostNameNonSecure() + '/' + privateMethods.cartURLWithThrottle();


    $cartBtn.attr('href', cartLink);
  };

  privateMethods.updateCartUrlforSecure = function _updateCartUrlforSecure($cartElement) {

    var newCartLink;
    var $cartElement = $('#headerCart');
    if (location.protocol == 'https:') {
      newCartLink = 'https://' + hfConfig.secureHostName + '/mycart/home';
    }
    $cartElement.attr('href', newCartLink);
  };

  // sets the blinds host name
  privateMethods.determineBlindsHostName = function determineBlindsHostName() {
    return (root.location.host.indexOf('hd-') === -1)
      ? 'blinds.homedepot.com'
      : 'qa-blinds.' + hfConfig.nonSecureHostName;
  };

  // sets the protocol
   /* istanbul ignore next */
  privateMethods.determineHostName = function determineHostName() {
    return root.location.protocol === 'http:'
      ? hfConfig.nonSecureHostName
      : hfConfig.secureHostName;
  };

  // wrapper to get non secure domain for desktop-tablet checkout
  privateMethods.hostNameNonSecure = function hostNameNonSecure() {
    return hfConfig.nonSecureHostName;
  };

  // determine if the drop down should be shown if we have blinds and merch in the cart
  privateMethods.showCartDropdown = function showCartDropdown() {
    return privateMethods.blinds !== '0' && privateMethods.mini !== '0';
  };

  // determines if to make "blinds item(s)" plural
  privateMethods.blindsPlural = function blindsPlural() {
    return privateMethods.blinds >= 2 ? 's' : '';
  };

  // determines if to make "cart item(s)" plural
  privateMethods.miniPlural = function miniPlural() {
    return privateMethods.mini >= 2 ? 's' : '';
  };

  // calculate the cart total to show
  privateMethods.total = function getTotal() {
    var blinds = privateMethods.blinds;
    var mini = privateMethods.mini;
    var total = (blinds + mini);

    return total > 99 ? '99+' : total;
  }

  // calculate the number of items in cart based off of the THD_MINICART cookie crumb
  privateMethods.getMinicartItemCount = function getMinicartItemCount(cookie) {
    var THD_PERSIST_COOKIE_NAME = 'THD_PERSIST=';
    var MINI_CART_COUNT_PROPERTY = 'I1';
    var THD_MINICART_CRUMB_NAME = 'C6=';
    var THD_MINICART_CRUMB_END_CHARS = '}:;';

    // if the THD_PERSIST cookie isn't there, then we cannot find the crumb with the cart item count
    if (cookie.indexOf(THD_PERSIST_COOKIE_NAME) === -1) {
      return 0;
    }

    var decodedCookie = decodeURIComponent(cookie);

    // if we do not see a C6 cookie crumb, then we cannot parse the cart item count
    if (decodedCookie.indexOf(THD_MINICART_CRUMB_NAME) === -1) {
      return 0;
    }

    // need to offset by 3 so we don't include the actual 'C6=' name in the minicart string
    var minicartCrumbStartIndex = decodedCookie.indexOf(THD_MINICART_CRUMB_NAME) + 3;
    // offsetting by 1 just so we can include the '}' in the indexOf call, to make it more failure proof
    // and to make sure that the ending curly bracket is included in the minicart string
    var minicartCrumbEndIndex = decodedCookie.indexOf(THD_MINICART_CRUMB_END_CHARS, minicartCrumbStartIndex) + 1;
    var thdMiniCartString = decodedCookie.substring(minicartCrumbStartIndex, minicartCrumbEndIndex);

    var minicartItemCount;
    try {
      var miniCartCookieObject = JSON.parse(thdMiniCartString);
      minicartItemCount = privateMethods.forceInteger(miniCartCookieObject[MINI_CART_COUNT_PROPERTY]);
    } catch(_) {
      // if the format of the cookie ever changes, the parse might throw an exception
      // in that situation we want to display 0 for the mini-cart count
      minicartItemCount = 0;
    }

    return minicartItemCount;
  }

  privateMethods.getCartItemsText = function _getCartItemsText(itemCount) {
    var unit = ((parseInt(itemCount, 10) === 1) ? 'item' : 'items');
    return (unit);
  };

  // updates the cart totals
  privateMethods.updateCartTotal = function _updateCartTotal($cartBtn) {
    var total = privateMethods.total();
    var isCartEmpty = privateMethods.isCartEmpty();

    $('#cartReminder__ItemCount').text(total);

    $cartBtn.find('.MyCart__itemCount').text(total);
    $cartBtn.find('.MyCart__itemCount__label').text(privateMethods.getCartItemsText(total));

    $cartBtn.find('.HeaderCartIcon .MyCart__contents').toggleClass('hide', isCartEmpty);

    return privateMethods;
  };

  // replete means "filled"
  privateMethods.isCartEmpty = function _isCartEmpty() {
    return (privateMethods.total() === 0);
  };

  // determine if the cart is replete with items
  privateMethods.updateCartIcon = function _updateCartIcon($cartDisplay) {
    var cartHasItems = !privateMethods.isCartEmpty();
    var $cartIcon = $cartDisplay.find('.MyCart__icon');

    $cartIcon.find('.HeaderIcon__primary')
      .toggleClass('HeaderIcon__primary--active', cartHasItems);

    $cartIcon.find('.HeaderIcon__secondary')
      .toggleClass('HeaderIcon__secondary--active', cartHasItems);
  };

  privateMethods.forceInteger = function _forceInteger(value) {
    return value ? parseInt(value, 10) : 0;
  };


  privateMethods.updateCartInfo = function _updateCartInfo() {
    var $cartBtn = $('.MyCart');
    // var $cartElement = $('#headerCart');
    privateMethods.blinds = privateMethods.forceInteger(cookieUtils.readBrowserCookie('Blinds_ComCartSamplesCount'));
    privateMethods.mini = privateMethods.getMinicartItemCount(document.cookie);

    // make public the fact that the cart drodpwn should be shown
    THD.HeaderFooter = {
      showCartDropdown: privateMethods.showCartDropdown()
    };

    privateMethods.updateCartTotal($cartBtn);

    privateMethods.updateCartIcon($cartBtn);

    privateMethods.updateCartUrl($cartBtn);

	// privateMethods.updateCartUrlforSecure($cartElement);
  };

  privateMethods.logCartInteraction = function _logCartInteraction(event) {
    var trackingItems = ['header', 'my cart'];
    var itemText;

    if (event) {
      // This gets the item we want, then removes child elements to remove the "X items" text
      itemText = $(this).closest('a').clone().find('*').remove().end().text().toLowerCase();

      trackingItems.push(itemText);
    }

    return headerAnalytics.logEvent('header click', trackingItems.join('>'));
  };

  // on start determine to show cart dropdown, update totals, and change UI
  privateMethods.onDomReady = function _onDomReady() {
    privateMethods.updateCartInfo();
  };

  privateMethods.cartURLWithThrottle = function _cartURLWithThrottle() {
    return 'mycart/home';
  };

  // Default Value
  cart.cartThrottle = true;

  /*
   * Public Methods
   */

  cart.init = function init() {
    // Delegates and ready initailizer
    $(document)
      .on('touchend click', '.MyCart__dropdown .MyCart__link', privateMethods.logCartInteraction)
      .on('cartUpdated', privateMethods.updateCartInfo)
      .ready(privateMethods.onDomReady);

    return this;
  };

  cart.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  return cart;
});
