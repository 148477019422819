import $ from 'jquery';
import * as hfConfig from 'hfConfig';
import * as Mustache from 'mustache';
import * as THD from 'global.namespace';
import * as Localizer from 'Localizer'
import * as analytics from 'header.analytics';

let instance;
let { userAddedDeliveryZip } = analytics;
let g_storeTrue = false;

class DeliveryZip{
  constructor(){
  this.deliveryZipInlineHtml = '<span class="zipCode">{{zip}}</span>' +
  '<svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" class="caret__deliveryZip" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'+
  '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">'+
  '<g transform="translate(-267.000000, -70.000000)" fill="#F96302">'+
  '<g transform="translate(10.000000, 67.000000)">'+
  '<polygon id="deliveryZipDropdown-arrow-shape" points="257 4.47169028 258.39064 3 262.987269 7.67425625 267.644626 3 269 4.47169028 262.987269 10.5"></polygon>'+
  '</g></g></g></svg>';
  this.deliveryZipDropdownHtml = '<div class="grid">'+
  '<button id="deliveryZipDropDownClose" class="DeliveryZipDropDown__close--right">'+
    '<img alt="close delivery zip drop down" src="https://assets.thdstatic.com/images/v1/close-symbol-orange.svg"'+
    '></img>'+
  '</button>'+
  '<div class="col__12-12 DeliveryZipDropDown__label">Enter delivery ZIP Code</div>' +
  '<div class="col__12-12 DeliveryZipDropDown__form">'+
    '<form id="deliveryDropDownForm" class="DeliveryZipDropDown">'+
      '<div class="DeliveryZipDropDown__form--input {{#isInvalidZip}}form-input--error{{/isInvalidZip}}">'+
        '<input type="tel" id="deliveryZipInput" placeholder="Enter Zip Code" class="DeliveryZipDropDown__input" value="" maxlength="5" autocomplete="off" autocapitalize="off" autocorrect="off">' +
      '</div>'+
      '<div class="DeliveryZipDropDown__form--alert">'+
      '<p class="{{^isInvalidZip}}DeliveryZipDropDown__form--alert-hide {{/isInvalidZip}}">'+
          '<span>Please enter a valid ZIP Code.</span>'+
      '</p>'+
       '</div>'+
        '<div class="bttn bttn--atc DeliveryZipDropDown__form--button {{#isInvalidZip}}DeliveryZipDropDown__button--error {{/isInvalidZip}}">'+
          '<button id="deliveryZipUpdateButton" class="bttn__content"><span class="DeliveryZipDropDown__form--button-loader hide"></span>Update</button>'+
        '</div>'+
    '</form>'+
  '</div>'+
  '<div class="col__12-12 DeliveryZipDropDown__line--align">'+
    '<hr>'+
  '</div>'+
  '<div class="col__12-12 DeliveryZipDropDown__info">'+
    '<div class="DeliveryZipDropDown__info--truck">'+
      '<img class="DeliveryZipDropDown__icn--truck" alt="truck delivery zip drop down" '+
      'src="https://assets.thdstatic.com/images/v1/truck-icon-orange.svg">'+
    '</div>'+
    '<div class="DeliveryZipDropDown__info--text">'+
      'Your ZIP Code helps us to provide <b>the most up-to-date </b>product and delivery information.'+
    '</div>'+
  '</div>'+
  '</div>';

  this.deliveryZipDropdownHtmlMobile = '<div class="DeliveryZipDropDown__arrow"></div>'+
  '<div class="grid">'+
  '<button id="deliveryZipDropDownClose" class="DeliveryZipDropDown__close--right">'+
    '<img alt="close delivery zip drop down" src="https://assets.thdstatic.com/images/v1/close-symbol-orange.svg"'+
    '></img>'+
  '</button>'+
  '<div class="col__12-12 DeliveryZipDropDown__label">Enter delivery ZIP Code</div>' +
  '<div class="col__12-12 DeliveryZipDropDown__form">'+
    '<form id="deliveryDropDownForm" class="DeliveryZipDropDown">'+
      '<div class="col__6-12 DeliveryZipDropDown__form--input {{#isInvalidZip}}form-input--error{{/isInvalidZip}}">'+
        '<input type="tel" id="deliveryZipInput" class="DeliveryZipDropDown__input" value="{{zip}}" maxlength="5" autocomplete="off" autocapitalize="off" autocorrect="off">'+
      '</div>'+
      '<div class="col__6-12 DeliveryZipDropDown__form--button-block">'+
        '<div class="bttn bttn--atc DeliveryZipDropDown__form--button {{#isInvalidZip}}DeliveryZipDropDown__button--error {{/isInvalidZip}}">'+
          '<button id="deliveryZipUpdateButton" class="bttn__content"><span class="DeliveryZipDropDown__form--button-loader hide"></span>Update</button>'+
        '</div>'+
      '</div>'+
      '<div class="DeliveryZipDropDown__form--alert">'+
        '<p class="{{^isInvalidZip}}DeliveryZipDropDown__form--alert-hide {{/isInvalidZip}}">'+
            '<span>Please enter a valid ZIP Code.</span>'+
        '</p>'+
      '</div>'+
    '</form>'+
  '</div>'+
  '<div class="col__12-12 DeliveryZipDropDown__info">'+
    '<div class="DeliveryZipDropDown__info--truck">'+
      '<img class="DeliveryZipDropDown__icn--truck" alt="truck delivery zip drop down" '+
      'src="https://assets.thdstatic.com/images/v1/truck-icon-orange.svg">'+
    '</div>'+
    '<div class="DeliveryZipDropDown__info--text">'+
      'Your ZIP Code helps us to provide <b>the most up-to-date </b>product and delivery information.'+
    '</div>'+
  '</div>'+
  '</div>';

  this.isTooltipOpen = false;

  this.init = this.init.bind(this);
  this.updateHtml = this.updateHtml.bind(this);
  this.openDropDown = this.openDropDown.bind(this);
  this.isValidZipCode = this.isValidZipCode.bind(this);
  };

  updateHtml(){
    let deliveryZipHtml;
    const zipDetails = Localizer.getDeliveryZipcodeDetails();
    if(zipDetails.zipcode){
      $('#deliveryStoreAndZip').removeClass('noDeliveryZip');
      deliveryZipHtml = Mustache.render(this.deliveryZipInlineHtml, {zip: zipDetails.zipcode});
      $('#zipContainer').find('.DeliveryZipInline__label').html(deliveryZipHtml);
        userAddedDeliveryZip(zipDetails.zipcode);
    } else {
        userAddedDeliveryZip('na');
    } 
  }

  constructDropdownContent(isValid){
    setTimeout(function(){
      var toolTipElement = $("#deliveryZipDropdown") ;
      var toolTipContainer = toolTipElement.parents('.ToolTip__wrapper');
      toolTipContainer.css('height', '214px');
      if (!isValid){
        toolTipContainer.css('height', '242px');
      }
    }, 50);
    const zipDetails = Localizer.getDeliveryZipcodeDetails();
    var zipDropdownHTML;
    if( $('#deliveryZipInput').val() !== '' && isValid){
       zipDropdownHTML = Mustache.render(this.deliveryZipDropdownHtml, {isInvalidZip:!isValid,  zip:zipDetails.zipcode});
    } else {
       zipDropdownHTML = Mustache.render(this.deliveryZipDropdownHtml, {isInvalidZip:!isValid,  zip:$('#deliveryZipInput').val()});
    }
    $('#deliveryZipDropdown').html(zipDropdownHTML);
  }

  constructDropdownContentMobile(isValid){
    const zipDetails = Localizer.getDeliveryZipcodeDetails();
    var zipDropdownHTML;
    if( $('#deliveryZipInput').val() !== '' && isValid){
       zipDropdownHTML = Mustache.render(this.deliveryZipDropdownHtmlMobile, {isInvalidZip:!isValid,  zip:zipDetails.zipcode});
    } else {
       zipDropdownHTML = Mustache.render(this.deliveryZipDropdownHtmlMobile, {isInvalidZip:!isValid,  zip:$('#deliveryZipInput').val()});
    }
     $('#deliveryZipDropdown').html(zipDropdownHTML);
   }

  deliveryTooltipInit(element){
    $(element).tooltip({
      type: 'click',
      noPad: true,
      closeOnClick: true,
      onOpen: instance.openDeliveryZipDropdownInTooltip
    });  
  }

  openDropDown(element){
    this.constructDropdownContent(true);
    this.deliveryTooltipInit(element);
    $(element).tooltip('open');
    this.isTooltipOpen = true;
    $('.caret__deliveryZip').addClass('caret__open');
    $(document).on('click', this.closeDropDown);
    setTimeout(function(){
      $('#deliveryZipInput').focus();
      var x = window.scrollX, y = window.scrollY;
      document.execCommand('selectAll', false, null);
      document.getSelection().collapseToEnd();
      window.scrollTo(x, y);
    }, 200);
    $(".ShoppingLinks__item").hoverIntent(function(){
      instance.isTooltipOpen = false;
      $('#zipContainer a').tooltip('close');
    });
  }
  
  openDropDownMobile(element){
    var sliderOpen = $("#deliveryZipDropdown").hasClass("hidden");
    if(!sliderOpen) {
      this.isTooltipOpen = false;
      $('#deliveryZipDropdown').addClass('hidden');
      $('.caret__deliveryZip').removeClass('caret__open');
    } else {
      this.constructDropdownContentMobile(true);
      this.isTooltipOpen = true;
      $(document).on('click', this.closeDropDown);
      var x = window.scrollX, y = window.scrollY;
      $('#deliveryZipInput').focus();
      window.scrollTo(x, y);
      setTimeout(function(){
        $('#deliveryZipDropdown').removeClass('hidden');
      },100);
      $('.caret__deliveryZip').addClass('caret__open');
    }
    // hide store selector, while opening the deliveryzip
    var hideStoreSelector = $("#myStore-overlay").hasClass("u--hide");
    if (!hideStoreSelector){
      $( "#myStoreMobile" ).click();
    }
    $("#myStore-wrapper").addClass("myStore-mask");
    $(document).trigger("headerMask-show", true);
    $("body").addClass("body_overflow--hidden");
  }

  closeDropDown(){
    if (hfConfig.currentChannel === 'mobile'){
      $('#deliveryZipDropdown').addClass('hidden');
      $('.caret__deliveryZip').removeClass('caret__open');
      if (instance.isTooltipOpen) {
        instance.isTooltipOpen = false;
        $("#myStore-wrapper").removeClass("myStore-mask");
        $(document).trigger("headerMask-show", false);
        $("body").removeClass("body_overflow--hidden");
      }
    }
    else if(instance.isTooltipOpen === true) {
      instance.isTooltipOpen = false;
      $('#zipContainer a').tooltip('close');
      $('.caret__deliveryZip').removeClass('caret__open');
    }
  } 

  onClickDeliveryZip(e){
    e.preventDefault();
    e.stopPropagation();
    const tooltipEnabled = $('#deliveryZipDropdown').hasClass('hidden');
    if (instance.isTooltipOpen && !tooltipEnabled){
      instance.closeDropDown(e);
      instance.isTooltipOpen = false;
    } else {
      const element = e.currentTarget;
      if (hfConfig.currentChannel === 'mobile'){
        instance.openDropDownMobile(element);
      } else {
        instance.openDropDown(element);
        setTimeout(function(){
          var x = window.scrollX, y = window.scrollY;
          document.execCommand('selectAll', false, null);
          document.getSelection().collapseToEnd();
          window.scrollTo(x, y);
          $('#deliveryZipInput').focus();
        }, 200);
      }
      if(!Localizer.getDeliveryZipcodeDetails().shownDefault){
        Localizer.updateDeliveryZipcodeDetails("", 'AUTO');
      }
    }
  }

  onClickCloseButton(e){
    const element = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    instance.closeDropDown(element);
  }

  isMyCartPage(){
    const { href } = window.location;
    return href.indexOf('/mycart/') !== -1;
  }

  isValidZipCode(zipCode){
    function zipLocalizerValidation(){
      var deferred = $.Deferred();
      $('#deliveryZipUpdateButton span').removeClass('hide');
      Localizer.validateUSZipcode(zipCode, 
        function successCallback(){
          deferred.resolve(true);
        }, 
        function failureCallback(){
          deferred.resolve(false);
        },
        function errorCallback(){
          deferred.resolve(false);
        })
      return deferred.promise();
    }

    function updateAfterValidation(){
      var promise = zipLocalizerValidation();
      promise.then(function(result) { 
        $('#deliveryZipUpdateButton span').addClass('hide');
        let isValid = result;
        let zipCode = $('#deliveryZipInput').val();
        if(!isValid){
          if (hfConfig.currentChannel === 'mobile'){
            instance.constructDropdownContentMobile(isValid);
          } else {
            instance.constructDropdownContent(isValid);
          }
          return;
        } else{
          Localizer.updateDeliveryZipcodeDetails(zipCode, 'USER');
          instance.closeDropDown("#deliveryZipDropdown");
          if(!instance.isMyCartPage()){
            setTimeout(function(){
              location.reload();
            }, 1000);
          }
        }
        return result;
      });
    }
    updateAfterValidation();
    return;
  }

  onClickUpdateButton(e){
    e.preventDefault();
    e.stopPropagation();
    let zipCode = $('#deliveryZipInput').val();
    if (zipCode.length === 5){
        instance.isValidZipCode(zipCode);
    } else {
      let isValid = false
      if (hfConfig.currentChannel === 'mobile'){
        instance.constructDropdownContentMobile(isValid);
      } else {
        instance.constructDropdownContent(isValid);
      }
    }
  }

  onKeyPressEnter(e){
    if (e.keyCode === 13) {
      instance.onClickUpdateButton(e);
    }
  }

  onKeyFocusDeliveryInput(e){
    $('#deliveryZipInput').parent().removeClass('form-input--error');
    $('.DeliveryZipDropDown__form--alert p').addClass('DeliveryZipDropDown__form--alert-hide');
    var toolTipElement = $("#deliveryZipDropdown") ;
    var toolTipContainer = toolTipElement.parents('.ToolTip__wrapper');
    toolTipContainer.css('height', '214px');
  }
  
  openDeliveryZipDropdownInTooltip($tooltip, complete){
    $('#deliveryZipDropdown')
      .removeClass('u--hide')
      .appendTo($tooltip);

      complete();
      $(document).trigger('openingHeaderToolTip', 'deliveryZip');
  };

  init(){

    Localizer.on(Localizer.event.LOCALIZED_STORE_CHANGE_GOOGLE, function () {
      g_storeTrue = true
    });
    setTimeout(function(){
      if (hfConfig.isPIPPage & Localizer.getDeliveryZipcodeDetails().type == "DEFAULT" & !g_storeTrue){
        $( "#zipContainer a" ).click();
        Localizer.updateDeliveryZipcodeDetails("", 'AUTO');
      }
    }, 1000);
    $(document).on('delivery_zip_updated', function(){
      $(document).trigger({type:'newDeliveryZipSet'});
    });
    Localizer.on(Localizer.event.DELIVERY_ZIP_UPDATED, function(){
      $(document).trigger({type:'newDeliveryZipSet'});
      $(document).trigger({type:'deliveryZipUpdated'}); 
    });
    Localizer.on(Localizer.event.LOCALIZED, function(){
      $(document).trigger({type:'newDeliveryZipSet'});
    });
    $(document)
    .on('newDeliveryZipSet', this.updateHtml)
    .on('click touchend', "#zipContainer a", this.onClickDeliveryZip)
    .on('click touchend', '#deliveryZipDropdown', function (event) {
      event.stopPropagation();
    })
    .on('click touchend', '#deliveryZipDropDownClose', this.onClickCloseButton)
    .on('click touchend', '#deliveryZipUpdateButton', this.onClickUpdateButton)
    .on('keypress', '#deliveryZipInput', this.onKeyPressEnter)
    .on('input', '#deliveryZipInput', function() {
      var nonNumReg = /[^0-9]/g
      $(this).val($(this).val().replace(nonNumReg, ''));
    })
    .on('focus', '#deliveryZipInput', this.onKeyFocusDeliveryInput)
    .on('wheel', "input[type=number]", function (event) {
      $(this).blur();
    });
    if (Localizer.status.isSelfLocalized() || Localizer.status.isAutoLocalized()) {
      $(document).ready(function () {
        /** Why is this here? */
        // this.deliveryTooltipInit;
        $(document).trigger({ type: 'newDeliveryZipSet' });
      });
    };
  };
}

if(!instance){
  instance = new DeliveryZip();
}
export default instance;

