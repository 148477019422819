/* globals Microsoft, define */
/*
  Purpose:
    - Module for the store finder dropdown and all it's interactions

  Dependencies:
    - jQuery
    - Mustache
    - THD Namespace
    - Microsoft
*/
(function closure(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define('header.storefinder', [
      'jquery',
      "body-scroll-lock",
      'global.namespace',
      'Localizer',
      'header.storefinder.core',
      'localization-drawer'
    ],
      function load(
        jQuery,
        bodyScrollLock,
        THD,
        Localizer,
        headerStorefinderCore,
        localizationDrawer
      ) {
        return factory(
          root,
          jQuery,
          bodyScrollLock,
          THD,
          Localizer,
          headerStorefinderCore,
          localizationDrawer
        );
      });
  } else {
    // For non-AMD module, expose this to global (will also be useful for test cases)
    /* eslint-disable */
    var _hfapp = THD.Utility.Namespace.createNamespace('THD.HFApp');
    _hfapp.storeFinder = factory(
      root,
      jQuery,
      THD,
      Localizer,
      THD.HFApp.headerStorefinderCore,
      THD.HFAPP.localizationDrawer
    );
    /* eslint-enable */
  }
})(this, function factory(
  root,
  $,
  bodyScrollLock,
  THD,
  Localizer,
  headerStorefinderCore,
  localizationDrawer
) {
  var storeFinder = {};
  var privateMethods = {};

  var privateData = {};
  var drawerElement = document.querySelector("#myStore-overlay");

  /**
   * Registers an event on document.
   * @param  {String} eventNames list of event names as String eg: 'click touch'
   * @param  {Function} handler  Handler function to be invoked upon event.
   * @param  {String} selector   Selector on which event to be triggered.[Optional]
   * @return {Object} $document  Jquery Document object
   */
  privateMethods.registerEventHandler = function _registerEventHandler(eventNames, handler, selector) {
    var $document = $(document);
    return $document.on(eventNames, selector, handler);
  };

  /**
   * Attach tooltip to mystore element on the page.
   */
  privateMethods.attachTooltipToMyStore = function _attachTooltipToMyStore() {
    // do a manual control over opening/closing
    $('#myStore').tooltip({
      type: 'manual',
      noPad: true,
      onOpen: headerStorefinderCore.openStorefinderInTooltip
    });
  };

  privateMethods.createMask = function _createMask() {
    let mask = document.getElementById('localization-mask');
    if (!mask) {
      mask = document.createElement('div');
      mask.classList.add('localization__mask--fixed');
      mask.id = 'localization-mask';
      mask.addEventListener('click', privateMethods.closeDrawer);
      document.body.appendChild(mask);
    }
  };

  privateMethods.updateStoreTitle = function _updateStoreTitle(event, html) {
    $('#myStore').find('.MyStore__label').html(html);
  };

  privateMethods.isScrolledToTop = function _isScrolledToTop() {
    return !(window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop
    );
  }

  privateMethods.launchDrawer = function _launchDrawer() {
    /* Close simple menu (created from hamburger) */
    $(document).trigger('showSimpleFlyout', false);
    privateData.isDrawerOpen = true;

    /* IF sticky header is present AND page is NOT scrolled to top,
       AKA, message bar is scrolled out of view
       THEN remove localization offset
       IF NOT sticky header, no action required
     */

    const stickyHeader = document.getElementsByClassName('Header3-sticky-desktop');
    if (stickyHeader.length > 0) {
      if (!privateMethods.isScrolledToTop()) {
        $('.localization').css('margin-top', 0);
      }
    }
    const homeDecorFurnitureSubnav = document.getElementsByClassName('homeDecorFurnitureSubnav');
    if (homeDecorFurnitureSubnav.length > 0) {
      $('.localization').css('margin-top', 0);
    }

    privateMethods.createMask();
    bodyScrollLock.disableBodyScroll(privateData.drawerElement);
    const localizationInstance = new localizationDrawer.default({
      channel: 'desktop',
      hostName: THD_GLOBAL.nonSecureHostName,
      selectedStoreId: privateData.myStore.storeId
    })
    localizationInstance.createDrawer();

    // close drawer when user hovers (with intent) over header footer items
    $(".ShoppingLinks__item").hoverIntent(function(){
      $(document).trigger('closeDrawer', false);
    });
  };

  privateMethods.closeDrawer = function _closeDrawer() {
    const mask = document.getElementById('localization-mask');
    const drawerContent = document.getElementById('myStore-overlayWrapper');
    const drawer = document.getElementById('myStore-overlay');
    if (mask && drawerContent && drawer && privateData.isDrawerOpen) {
      privateData.isDrawerOpen = false;
      document.body.removeChild(mask);
      drawerContent.innerHTML = '';
      drawer.classList.add('u--hide');
      bodyScrollLock.enableBodyScroll(privateData.drawerElement);
    }
  };

  privateMethods.toggleDrawer = function _toggleDrawer(event) {
    event.preventDefault();
    if (privateData.isDrawerOpen) {
      privateMethods.closeDrawer();
    } else {
      privateMethods.launchDrawer();
    }
  };

  /*
   * Public Methods
   */
  storeFinder.init = function _init() {
    Localizer.localize().then(function (response) {
      privateData.myStore = response.getLocalizedStore() || {
        storeId: "0121"
      };
      $(document).ready(function () {
        if (Localizer.status.isSelfLocalized() || Localizer.status.isAutoLocalized()) {
          privateMethods.attachTooltipToMyStore();
        }

        privateMethods.registerEventHandler('click touchend', headerStorefinderCore.handleClickOnStorefinder, '#myStore');
      });
    });

    privateMethods.registerEventHandler('click touchend', headerStorefinderCore.closeMenuWithDelay, '#myStoreDropdown [rel="hfLocModal"]');

    privateMethods.registerEventHandler('click touchend', privateMethods.toggleDrawer, '[rel="hfLocModal"]');
    privateMethods.registerEventHandler('launchLocDrawer', privateMethods.toggleDrawer);
    privateMethods.registerEventHandler('updateStoreTitle', privateMethods.updateStoreTitle);

    $(document)
      .on("click touchend", "#simpleMenuButton", privateMethods.closeDrawer)
      .on("searchState", privateMethods.closeDrawer)
      .on("closeDrawer", privateMethods.closeDrawer);

    return this;
  };

  storeFinder.getPrivateMethods = function getPrivateMethods() {
    return privateMethods;
  };

  storeFinder.getPrivateData = function getPrivateData() {
    return privateData;
  };

  return storeFinder;
});
